import React from 'react';
import { Stack, Box, Tab, Tabs } from '@mui/material';
import { styled } from "@mui/material/styles";

export default function MesTab({
    tabTitle = ["DefaultValue1", "DefaultValue2", "DefaultValue3", "DefaultValue4", "DefaultValue5", "DefaultValue6", "DefaultValue7"],
    tabSize = 'tab-size',
    tabContainerSize = 'tab-container-size',
    handleChange = () => alert('Tab is Clicked.'),
    withScrollButtons = false,
    value = null,
}) {
      const StyledTab = styled(Tab)({
         "&.Mui-selected": {
             color: "#4376AF"
         }
     });

    return (


        <Box sx={{ width: '100%' }}>
            <Tabs
                value={value}
                scrollButtons={withScrollButtons}
                variant="scrollable"
                onChange={handleChange}
                direction="row"
                className={tabSize}
                flexContainer="flexContainer"
            >
                {tabTitle.map((label, index) => {
                    return (
                        <Tab
                            key={index}
                            className={tabContainerSize}
                            label={label}
                            wrapped
                            disableRipple />
                    );
                })
                }
            </Tabs>
        </Box>
    )
}