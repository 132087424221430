import React from 'react';
import { Paper, Typography } from '@mui/material';
import MesSliderTitle from '../../components/mesTitlePage/MesSliderTitle';
import { ReactComponent as ConnectorConfigureSliderIcon } from '../../components/shared/images/eapSliderIcons/ConnectorConfigureSliderIcon.svg';
import { useTranslation } from 'react-i18next';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy } from 'react-syntax-highlighter/dist/esm/styles/prism';

const Configure = ({ connectorConfigure }) => {
    const { t } = useTranslation();

    return (
        <Paper className='content-table' elevation={4} sx={{ width: '100%', padding: '12px' }}>
            <div style={{ marginBottom: '14px' }}>
                <MesSliderTitle classNameSliderGrid='eapConnector-SliderIcon' classNameTitleText='eapConnector-SliderTitle' SliderTitleIcon={ConnectorConfigureSliderIcon} TitleText={t("Connector.Configure")} />
            </div>
            <SyntaxHighlighter language="xml" style={coy} showLineNumbers>
                {connectorConfigure}
            </SyntaxHighlighter>
        </Paper>
    );
};

export default Configure;
