import * as SettingConst from "../const/SettingConst";
import SettingModel from "../models/SettingModel";
import SettingData from '../data/SettingData';

const initialState = {
    SettingModel: new SettingModel(),
    SettingData: SettingData,
};


const SettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case SettingConst.SET_SETTING_DATA_VALUE:
            var newStateObj = { ...Object.assign({}, state, { "SettingModel": action.payload }) };
            return newStateObj;
        case SettingConst.SET_SETTING_DATA_ONCE:
            var getSettingDataOnce = { ...Object.assign({}, state, { "SettingData": action.payload }) };
            return getSettingDataOnce;
        default:
            return state;
    }
};

export default SettingReducer;