import React from 'react';
import '../shared/MesCustomStyle.css';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CantierHeaderMenuIcon } from '../shared/images/defaultIcons/HeaderMenuDefaultIcon.svg';

const checkDefaultButtonClick = () => {
    // Your logic for handling the button click goes here
    alert('Menu Icon Button clicked');
};

function MesMenuButton({
    iconButtonSX = {},
    MenuButtonIcon = CantierHeaderMenuIcon,
    classNameIconButton = 'main-sidenav-icon-button',
    classNameMenuButton = 'main-sidenav-menu-icon',
    onButtonClick = checkDefaultButtonClick,
    classNameButtonDiv = 'main-sidenav-button-div',
}) {

    return (
        <IconButton elevation={0} aria-label="Header Menu Icon Button" sx={iconButtonSX} className={classNameIconButton} onClick={onButtonClick} disableRipple>
            <MenuButtonIcon className={classNameMenuButton} />
        </IconButton>
    );
}


export default MesMenuButton;
