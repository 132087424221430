import React from 'react';
import '../shared/MesInputStyle.css';
import { Button, IconButton, Grid, Typography, Stack } from '@mui/material';
import { ReactComponent as DefaultIcon } from '../shared/images/pageIcons/PlusSignIcon.svg';

const checkDefaultButtonClick = () => {

    alert('Text Button clicked');
};

export default function MesLabelAndIconButton({
    Label = "Test Button",
    classNameButton = 'customized-icon-button',
    classNameText = 'cantier-text-button-label',
    classNameTextWithStartICon = 'cantier-text-button-label-with-start-icon',
    classNameTextWithEndICon = 'cantier-text-button-label-with-end-icon',
    classNameStartIcon = 'custom-button-start-icon',
    classNameEndIcon = 'custom-button-end-icon',
    OnClickHandler = checkDefaultButtonClick,
    Icon = DefaultIcon,
    endIconButton = DefaultIcon,
    hasIconButton = true,
    isStartIcon = true,
    ButtonType = "button",
    classNameButtonDiv = 'custom-label-and-icon-button-grid',
}) {

    return (
        <div className={classNameButtonDiv} >
            <Grid container spacing={0}>
                {
                    hasIconButton ?
                        isStartIcon ?
                            <Grid item xs={12} alignItems="center" justifyContent="center">
                                <IconButton disableRipple className={classNameButton} aria-label="Start Icon Text Button" onClick={OnClickHandler} >
                                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                                        <div>
                                            <Icon className={classNameStartIcon} />
                                        </div>
                                        <div>
                                            <Typography className={classNameTextWithStartICon}>{Label}</Typography>
                                        </div>
                                    </Stack>
                                </IconButton>
                            </Grid>
                            :
                            <Grid item xs={12}>
                                <IconButton style={{ justifyContent: "flex-start" }} fullWidth={true} disableRipple className={classNameButton} aria-label="End Icon Text Button" onClick={OnClickHandler} >
                                    <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                                        <div>
                                            <Typography className={classNameTextWithEndICon}>{Label}</Typography>
                                        </div>
                                        <div>
                                            <Icon className={classNameEndIcon} />
                                        </div>
                                    </Stack>
                                </IconButton>
                            </Grid>
                        :
                        <Grid item xs={12}>
                            <Button type={ButtonType} className={classNameButton} disableRipple aria-label="Text Button" onClick={OnClickHandler} >
                                <Typography className={classNameText}>{Label}</Typography>
                            </Button >
                        </Grid>
                }
            </Grid>
        </div>
    );
};
