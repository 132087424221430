import { call, put, takeEvery } from 'redux-saga/effects';
import * as SettingConst from "../const/SettingConst";
import * as SettingAction from "../actions/SettingAction";
import * as SettingApi from '../apis/SettingApi';

export function* watchSettingOnGetDataValues() {
    yield takeEvery(SettingConst.GET_SETTING_DATA_VALUE, fnSettingGetDataValues);
}
export function* watchSettingOnGetDataOnce() {
    yield takeEvery(SettingConst.GET_SETTING_DATA_ONCE, fnSettingGetDataOnce);
}

export function* watchSettingOnSubmitOrUpdateData() {
    yield takeEvery(SettingConst.CREATE_OR_UPDATE_SETTING_DATA, fnSettingCreateOrUpdateData);
}

export function* watchSettingOnDeleteData() {
    yield takeEvery(SettingConst.DELETE_SETTING_DATA, fnSettingDeleteData);
}

function* fnSettingGetDataValues() {
    try {
        var result = yield call(SettingApi.getSettingData);
        //console.log(result)
        yield put(SettingAction.SettingSetDataValue(result));
    }
    catch (e) {
        console.log(e)
    }
    finally {
      
    }
}

// Grid Table related functions*

function* fnSettingGetDataOnce() {
    try {
        var result = yield call(SettingApi.getSettingDataOnce);
        // Leads to a GET Method. If fetching all data, no need to add a second argument
        // Otherwise, use a second argument to fetch a single or multiple data
        console.log(result)
        yield put(SettingAction.SettingSetDataOnce(result));
    }
    catch (e) {
        console.log(e)
    }
    finally {

    }
}

function* fnSettingCreateOrUpdateData(params) {
    try {
        var result = yield call(SettingApi.createOrUpdateSettingData, params.payload);
        console.log(result);
        var output = yield call(SettingApi.getSettingDataOnce);
        yield put(SettingAction.SettingSetDataOnce(output));
    }
    catch (e) {
        console.log(e)
    }
    finally {

    }
}

function* fnSettingDeleteData(params) {
    try {
        var result = yield call(SettingApi.deleteSettingData, { "id": params.payload} );
        console.log(result);
        var output = yield call(SettingApi.getSettingDataOnce);
        yield put(SettingAction.SettingSetDataOnce(output));
    }
    catch (e) {
        console.log(e)
    }
    finally {

    }
}   

// End of Grid Table related functions*