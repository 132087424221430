
export const LogViewerLocalData = [
    { id: 1, orgStructDetail: 'Tyre Building', orgStructDetailCode: '1A' },
    { id: 2, orgStructDetail: 'Operating Mechanics', orgStructDetailCode: '2B' },
    { id: 3, orgStructDetail: 'Research Group', orgStructDetailCode: '3C' },
    { id: 4, orgStructDetail: 'Human Resources', orgStructDetailCode: '4D' },
    { id: 5, orgStructDetail: 'Tyre Building', orgStructDetailCode: '5E' },
    { id: 6, orgStructDetail: 'Operating Mechanics', orgStructDetailCode: '6F' },
    { id: 7, orgStructDetail: 'Research Group', orgStructDetailCode: '710' },
    { id: 8, orgStructDetail: 'Human Resources', orgStructDetailCode: '811' },
    { id: 9, orgStructDetail: 'Tyre Building', orgStructDetailCode: '912' },
    { id: 10, orgStructDetail: 'Operating Mechanics', orgStructDetailCode: 'A13' },
    { id: 11, orgStructDetail: 'Research Group', orgStructDetailCode: 'B14' },
    { id: 12, orgStructDetail: 'Human Resources', orgStructDetailCode: 'C15' },
    { id: 13, orgStructDetail: 'Tyre Building', orgStructDetailCode: 'D16' },
    { id: 14, orgStructDetail: 'Operating Mechanics', orgStructDetailCode: 'E17' },
    { id: 15, orgStructDetail: 'Research Group', orgStructDetailCode: 'F18' },
    { id: 16, orgStructDetail: 'Human Resources', orgStructDetailCode: '1019' },
    { id: 17, orgStructDetail: 'Tyre Building', orgStructDetailCode: '111A' },
    { id: 18, orgStructDetail: 'Operating Mechanics', orgStructDetailCode: '121B' },
    { id: 19, orgStructDetail: 'Research Group', orgStructDetailCode: '131C' },
    { id: 20, orgStructDetail: 'Human Resources', orgStructDetailCode: '141D' },
    { id: 21, orgStructDetail: 'Research Group', orgStructDetailCode: '151E' },
    { id: 22, orgStructDetail: 'Human Resources', orgStructDetailCode: '161F' },
    { id: 23, orgStructDetail: 'Tyre Building', orgStructDetailCode: '1720' },
    { id: 24, orgStructDetail: 'Operating Mechanics', orgStructDetailCode: '1821' },
    { id: 25, orgStructDetail: 'Research Group', orgStructDetailCode: '1922' },
    { id: 26, orgStructDetail: 'Human Resources', orgStructDetailCode: '1A23' },
    { id: 27, orgStructDetail: 'Tyre Building', orgStructDetailCode: '1B24' },
    { id: 28, orgStructDetail: 'Operating Mechanics', orgStructDetailCode: '1C25' },
    { id: 29, orgStructDetail: 'Research Group', orgStructDetailCode: '1D26' },
    { id: 30, orgStructDetail: 'Human Resources', orgStructDetailCode: '1E27' },
];

export const LogViewerDetailData = [
    { id: 1, label: 'Tyre Building' },
    { id: 2, label: 'Operating Mechanics' },
    { id: 3, label: 'Research Group' },
    { id: 4, label: 'Human Resources' },
]