import React from 'react';
import '../shared/MesCustomStyle.css'
import { Stack, Grid } from '@mui/material';
import { ReactComponent as CantierDefaultLogo } from '../shared/images/defaultIcons/CantierLogoDefaultIcon.svg';

function MesTitlePage({
    HeaderLogo = CantierDefaultLogo,
    classNameHeaderLogo = 'default-custom-header-logo',
    HeaderTitleText = 'IIoT Platform',
    classNameHeaderTitleText = 'default-header-title-default-text'
}) {
    return (
        <Stack alignItems="center" position="row" justifyContent="center" spacing={0} useFlexGap flexWrap="wrap">
            <Grid container spacing={0}>
                <Grid item xs={12} className="custom-headertitle-grid">
                    <Grid item xs={6} marginLeft='12px' >
                        <HeaderLogo className={classNameHeaderLogo} />
                    </Grid>
                    <Grid item xs={6}>
                        <span className={classNameHeaderTitleText}>{HeaderTitleText}</span>
                    </Grid>
                </Grid>
            </Grid>
        </Stack>
    );
};

export default MesTitlePage;
