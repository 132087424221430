
export const getConnectorData = () => {
    return fetch('/connector/api', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
        }
    })
    .then(response => response.json())
    .catch((error) => { 
        throw error;
    })
}

export const getConnectorDataOnce = () => {
    return fetch('/connector/api/GetConnectors', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
        }
    })
    .then(response => response.json())
    .catch((error) => { 
        throw error;
    })
}

export const createOrUpdateConnectorData = (data) => {
    return fetch('/connector/api/CreateOrUpdateConnector', {
        method: 'POST',
        body: JSON.stringify(data), // Convert data to JSON
        headers: {
        'Content-Type': 'application/json', // Set appropriate headers
        },
    })
    .then(response => response.json())
    .catch((error) => {
        throw error;
    });
}

export const deleteConnectorData = (data) => {
    return fetch('/connector/api/DeleteConnector', {
      method: 'POST',
      //method: 'DELETE',
      body: JSON.stringify(data), // Convert data to JSON
      headers: {
        'Content-Type': 'application/json', // Set appropriate headers
      },
    })
    .then(response => response.json())
    .catch((error) => {
        throw error;
    });
}
