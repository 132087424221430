import * as LogViewerConst from "../const/LogViewerConst";

export const LogViewerSetDataValue = (params) => {

    return {
        type: LogViewerConst.SET_LOG_VIEWER_DATA_VALUE,
        payload: params
    };
}

export const LogViewerGetDataValue = (params) => {

    return {
        type: LogViewerConst.GET_LOG_VIEWER_DATA_VALUE,
        payload: params
    };
}

export const LogViewerGetDataOnce = (params) => {

    return {
        type: LogViewerConst.GET_LOG_VIEWER_DATA_ONCE,
        payload: params
    };
}

export const LogViewerSetDataOnce = (params) => {

    return {
        type: LogViewerConst.SET_LOG_VIEWER_DATA_ONCE,
        payload: params
    };
}

export const LogViewerSubmitData = (params) => {

    return {
        type: LogViewerConst.SUBMIT_LOG_VIEWER_DATA,
        payload: params
    };
}

export const LogViewerStartTime = (params) => {
    return {
        type: LogViewerConst.START_LOG_VIEWER_DATA,
        payload: params
    };
}

export const LogViewerStopTime = (params) => {
    return {
        type: LogViewerConst.STOP_LOG_VIEWER_DATA,
        payload: params
    };
}


export const LogViewerDeleteData = (params) => {

    return {
        type: LogViewerConst.DELETE_LOG_VIEWER_DATA,
        payload: params
    };
}

export const LogViewerUpdateData = (params) => {

    return {
        type: LogViewerConst.UPDATE_LOG_VIEWER_DATA,
        payload: params
    };
}