
export const getLogViewerData = () => {
    return fetch('/logviewer', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
        }
    })
    .then(response => response.json())
    .catch((error) => { 
        throw error;
    })
}

export const getLogViewerDataOnce = () => {
    return fetch('/logviewer/getdataonce', {
        method: 'GET',
        headers: {
            'Content-type': 'application/json',
        }
    })
    .then(response => response.json())
    .catch((error) => { 
        throw error;
    })
}

export const submitLogViewerData = (data) => {
    return fetch('/logviewer/submitdata', {
        method: 'POST',
        body: JSON.stringify(data), // Convert data to JSON
        headers: {
        'Content-Type': 'application/json', // Set appropriate headers
        },
    })
    .then(response => response.json())
    .catch((error) => {
        throw error;
    });
}

export const startLogViewerData = (data) => {
    return fetch('/logviewer/StartTime', {
        method: 'POST',
        body: JSON.stringify(data), // Convert data to JSON
        headers: {
            'Content-Type': 'application/json', // Set appropriate headers
        },
    })
    .then(response => response.json())
    .catch((error) => {
        throw error;
    });
}

export const stopLogViewerData = (data) => {
    return fetch('/logviewer/StopTime', {
        method: 'POST',
        body: JSON.stringify(data), // Convert data to JSON
        headers: {
            'Content-Type': 'application/json', // Set appropriate headers
        },
    })
    .then(response => response.json())
    .catch((error) => {
        throw error;
    });
}

export const deleteLogViewerData = (data) => {
    return fetch('/logviewer/deletedata', {
      method: 'DELETE',
      body: JSON.stringify(data), // Convert data to JSON
      headers: {
        'Content-Type': 'application/json', // Set appropriate headers
      },
    })
    .then(response => response.json())
    .catch((error) => {
        throw error;
    });
}

export const updateLogViewerData = (data) => {
    return fetch('/logviewer/updatedata', {
        method: 'PATCH',
        body: JSON.stringify(data), // Convert data to JSON
        headers: {
            'Content-Type': 'application/json', // Set appropriate headers
        },
    })
    .then(response => response.json())
    .catch((error) => {
        throw error;
    });
}