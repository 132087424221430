import Connector from "./eapPage/connectorUI/Connector";
import LogViewer from "./eapPage/logViewerUI/LogViewer";
import Message from "./eapPage/messageUI/Message";
import Service from "./eapPage/serviceUI/Service";
import Setting from "./eapPage/settingUI/Setting";
const AppRoutes = [
    {
        path: '/connector',
        element: <Connector />
    },
    {
        path: '/log-viewer',
        element: <LogViewer />
    },
    {
        path: '/message',
        element: <Message />
    },
    {
        path: '/service',
        element: <Service />
    },
    {
        path: '/setting',
        element: <Setting />
    }
    
];

export default AppRoutes;
