import React, { useState } from 'react';
import { Table } from 'antd';

export default function Event({ data }) {
    const [pageSize, setPageSize] = useState(10);

    const columns = [
        { title: 'CEID', dataIndex: 'ceid', key: 'ceid', render: text => text || 'N/A' },
        { title: 'Alias', dataIndex: 'alias', key: 'alias', render: text => text || 'N/A' },
        { title: 'CEID Format', dataIndex: 'ceidFormat', key: 'ceidFormat', render: text => text || 'N/A' },
        { title: 'Description', dataIndex: 'description', key: 'description', render: text => text || 'N/A' },
    ];

    const dataSource = Object.keys(data).map(key => ({
        key,
        ...data[key],
    }));

    const handleTableChange = (pagination) => {
        setPageSize(pagination.pageSize);
    };

    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{ 
                pageSize: pageSize, 
                showSizeChanger: true, 
                pageSizeOptions: ['10', '20', '50', '100'] 
            }}
            onChange={handleTableChange}
            title={() => ''}
            bordered
        />
    );
}
