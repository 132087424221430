import * as ConnectorConst from "../const/ConnectorConst";
import ConnectorModel from "../models/ConnectorModel";
import ConnectorData from '../data/ConnectorData';

const initialState = {
    ConnectorModel: new ConnectorModel(),
    ConnectorData: ConnectorData,
};


const ConnectorReducer = (state = initialState, action) => {
    switch (action.type) {
        case ConnectorConst.SET_CONNECTOR_DATA_VALUE:
            var newStateObj = { ...Object.assign({}, state, { "ConnectorModel": action.payload }) };
            return newStateObj;
        case ConnectorConst.SET_CONNECTOR_DATA_ONCE:
            var getConnectorDataOnce = { ...Object.assign({}, state, { "ConnectorData": action.payload }) };
            return getConnectorDataOnce;
        default:
            return state;
    }
};

export default ConnectorReducer;