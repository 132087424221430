import React from 'react';
import '../shared/MesCustomStyle.css';
import { Stack, Box, Typography } from '@mui/material';
import { ReactComponent as ChartIcon } from '../shared/images/assets/ChartIcon.svg'

export default function MesDashboard({
    classNameLabel = 'custom-dashboard-label-value',
    classNameAmount = 'custom-dashboard-number-value',
    dashboardIconClassName = 'custom-paper-icon-size',
    classNameDashboard = 'custom-dashboard-box',
    classNameStack = 'custom-style-box-container',
    Spacing = 0,
    hasIcon = true,
    dashboardItems = [
        { icon: <ChartIcon />, label: 'Received Quantity', amount: 1650, dashboardIconClassName: 'custom-paper-icon-size' },
        { icon: <ChartIcon />, label: 'Produced Quantity', amount: 276, dashboardIconClassName: 'custom-paper-icon-size' },
        { icon: <ChartIcon />, label: 'Rework Quantity', amount: 423, dashboardIconClassName: 'custom-paper-icon-size' },
        { icon: <ChartIcon />, label: 'NG Quantity', amount: 12, dashboardIconClassName: 'custom-paper-icon-size' }
    ]
}) {

    return (
        <Stack direction="row" className={classNameDashboard}>
            {dashboardItems !== undefined && dashboardItems.length !== 0 ?
                dashboardItems.map((item, index) => {
                    return (
                        <Stack direction="row" className={classNameStack} key={index} spacing={Spacing}>
                            {hasIcon ?
                                <div>
                                    <Box className={item.dashboardIconClassName}>{item.icon}</Box>
                                </div>
                                :
                                null
                            }
                            <div>
                                <Box sx={{ width: 'fit-content' }} >
                                    <Typography className={classNameLabel}>{item.label}</Typography>
                                    <Typography className={classNameAmount}>{item.amount}</Typography>
                                </Box>
                            </div>
                        </Stack>
                    );
                })
                :
                null
            }
        </Stack>

    );
}