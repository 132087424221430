import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Select, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormControl } from '@mui/material';

const { Option } = Select;

export default function PrimaryMessage({ selectedEquipment, equipmentStatus }) {
    const { t } = useTranslation();
    const [config, setConfig] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState('');

    const messages = [
        { stream: '1', function: '1', name: 'S1F1 Are You There Request' },
        { stream: '1', function: '3', name: 'S1F3 Selected Equipment Status Request' },
        { stream: '1', function: '13', name: 'S1F13 Establish Communications Request' },
        { stream: '1', function: '15', name: 'S1F15 Request OFF-LINE' },
        { stream: '1', function: '17', name: 'S1F17 Request ON-LINE' },
        { stream: '7', function: '19', name: 'S7F19 Current EPPD Request' }
    ];

    useEffect(() => {
        fetch('/config.json')
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error loading config:', error));
    }, []);

    const handleChange = (value) => {
        setSelectedMessage(value);
    };

    const handleSendMessage = () => {
        if (selectedMessage && equipmentStatus === "Connected") {
            const [stream, func] = selectedMessage.split('F');
            const postData = {
                stream,
                function: func,
                EquipmentName: selectedEquipment
            };

            axios.post(`${config.apiBaseUrl}/api/SendSECSPrimaryMessage`, postData)
                .then(response => {
                    message.success(`${response.data}`);
                })
                .catch(error => {
                    message.error(`${error.message}`);
                });
        } else {
            message.warning("Equipment is not connected. Cannot send message.");
        }
    };

    return (
        <div>
            <p>{t("CustomService.Equipment")}: {selectedEquipment}</p>
            <p>{t("Connector.Status")}: {equipmentStatus}</p>
            <FormControl variant="outlined" style={{ minWidth: 300, marginRight: 20 }}>
                <Select
                    placeholder={t("CustomService.SelectMessage")}
                    onChange={handleChange}
                    style={{ width: '100%' }}
                >
                    {messages.map((message, index) => (
                        <Option key={index} value={`${message.stream}F${message.function}`}>
                            {message.name}
                        </Option>
                    ))}
                </Select>
            </FormControl>
            <Button type="primary" onClick={handleSendMessage} disabled={!selectedMessage || equipmentStatus !== "Connected"}>
            {t("CustomService.SendMessage")}
            </Button>
        </div>
    );
}
