import * as React from 'react';
import '../shared/MesCustomStyle.css';
import { styled } from '@mui/material/styles';
import { Table, TableBody, TableContainer, TableHead, TableRow, Paper, Checkbox, TextField } from '@mui/material/';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import EditAndDeleteButton from './EditAndDeleteButton';
import SkillSetsWithMonitorIcon from './SkillSetsWithMonitorIcon';
import SearchWithFilterIcon from './SearchWithFilterIcon';
import AddSkillButton from './AddSkillButton';
import { ReactComponent as Vector } from '../shared/images/mesSlidingFormIcons/plusIcon.svg';


// StyledTableCell component for customizing TableCell styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#f2f2f2',
    color: 'darkblue',
    fontSize: '15px',
    fontWeight: 'bold',
    textAlign: 'center',
    border: 'none', 
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: 'white',
    fontSize: '15px',
    textAlign: 'center',
    border: 'none',
  },
}));

// StyledTableRow component for customizing TableRow styles
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    borderBottom: 'none',
  },
  borderBottom: '1px solid lightgray',
}));

// StyledTableContainer for customizing TableContainer styles
const StyledTableContainer = styled(TableContainer)({
  padding: '0',
  boxShadow: 'none',
});

// SlidingTable component with styled components
function SlidingTable() {
  const [data, setData] = React.useState([
    { id: 1, name: '1Lorem Ipsum Text Lorem Ipsum Text Lorem' },
    { id: 2, name: '2Lorem Ipsum Text Lorem Ipsum Text Lorem' },
    { id: 3, name: '3Lorem Ipsum Text Lorem' },
    { id: 4, name: '' },
  ]);

  return (
    <>
      <div className="sliding-container">
        <div>
          <AddSkillButton />
        </div>
        <div className="sliding-form-parent-container">
          <div className="search-with-filter">
            <SkillSetsWithMonitorIcon />
          </div>
          <div className="center-space"></div>
          <div className="right-corner">
            <SearchWithFilterIcon />
          </div>
        </div>
        <StyledTableContainer component={Paper} >
          <Table sx={{ minWidth: 685 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center"></StyledTableCell>
                <StyledTableCell align="center">Skill Set</StyledTableCell>
                <StyledTableCell align="center">
                  <Vector className="vector-svg" />
                  Actions
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                // Apply the StyledTableRow component
                <StyledTableRow key={row.id}>
                  <StyledTableCell align="center">
                    <Checkbox
                      size="small"
                      style={{
                        color: 'gray',
                        backgroundColor: 'white',
                      }}
                    />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    {row.id === 4 ? (
                      <TextField
                        id="edit-text-field"
                        value={row.name}
                        onChange={(e) =>
                          setData((prevData) =>
                            prevData.map((item) =>
                              item.id === 4
                                ? { ...item, name: e.target.value }
                                : item
                            )
                          )
                        }
                        sx={{ width: '100%', height: '50%' }}
                      />
                    ) : (
                      row.name
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <EditAndDeleteButton />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </div>
    </>
  );
}

export default SlidingTable;


