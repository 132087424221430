import React from 'react';
import '../../components/shared/MesCustomStyle.css'
import { Avatar, Divider, IconButton, Menu, MenuItem, ListItemIcon, Tooltip } from '@mui/material';
import { Logout, PersonAdd, Settings } from '@mui/icons-material';



function MesUserAccountMenuButton({ classNameIcon, Icon,
    TooltipTitle, IconButtonSize, MenuItem1, MenuItem2, MenuItem3, MenuItem4, MenuItem5,
    classNameMenuItem1, classNameMenuItem2, classNameMenuItem3, classNameMenuItem4, classNameMenuItem5,
    handleLogout

}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
            <React.Fragment >
                <Tooltip title={TooltipTitle}>
                        <IconButton
                        disableRipple={true}
                        onClick={handleClick}
                        size={IconButtonSize}
                        sx={ { ml: 1, mr: 2.75 } }
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                            <Avatar sx={{ width: "36px", height: "36px" }}><Icon sx={{ width: "36px", height: "36px" }} className={classNameIcon} /></Avatar>
                    </IconButton>
                </Tooltip>
            <Menu anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                disableRipple={true}
                PaperProps={{
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 0px 0px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            bgcolor: "#C0C0C0",
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                <MenuItem onClick={handleClose} disableRipple={true}>
                    <Avatar /> <span className={classNameMenuItem1} >{MenuItem1}</span>
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple={true}>
                    <Avatar /> <span className={classNameMenuItem2} >{MenuItem2}</span>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose} disableRipple={true}>
                    <ListItemIcon>
                        <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    <span className={classNameMenuItem3} >{MenuItem3}</span>
                </MenuItem>
                <MenuItem onClick={handleClose} disableRipple={true}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    <span className={classNameMenuItem4} >{MenuItem4}</span>
                </MenuItem>
                <MenuItem onClick={handleLogout ? handleLogout : () => alert('Logout Test')} disableRipple={true}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    <span className={classNameMenuItem5} >{MenuItem5}</span>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

export default MesUserAccountMenuButton;