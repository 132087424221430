import React, { useState, useEffect } from 'react';
import '../shared/MesCustomStyle.css';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as SideNavDrawerIcon } from '../shared/images/sideNavIcons/SideNavDrawerIcon.svg';
import { ReactComponent as FilterIconOutlined } from '../shared/images/assets/FilterIconOutlined.svg';
import MesIconButton from '../mesButton/MesIconButton';
import MenuButton from '../mesButton/MesMenuButton';
import { Box, Stack } from '@mui/material';
import MesFilterSearchBarAlt from '../mesSearchBar/MesFilterSearchBarAlt';
import { ReactComponent as ButtonPlusSignIcon } from '../shared/images/buttonIcons/ButtonPlusSignIcon.svg';
import { ReactComponent as ButtonClearIcon } from '../shared/images/buttonIcons/ButtonClearIcon.svg';
import { ReactComponent as DeleteIcon } from '../shared/images/assets/DeleteIcon.svg';
import { NavbarBrand } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

const { Option } = Select;

export default function MesTopBar({
    SideNavWidth = 280,
    SideNavOpen = false,
    SideNavClickHandler = () => { }
}) {

    const theme = useTheme();
    const drawerWidth = SideNavWidth;
    const { i18n } = useTranslation();
    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 50,
    });

    const slidingAppBar = {
        '&.main-menu-icon-button-open': {
            width: drawerWidth,
            ...(SideNavOpen && {
                ...openedMixin(theme),
            }),
            ...(!SideNavOpen && {
                ...closedMixin(theme),
            }),
        },
    };

    const hideSearchBar = {
        mr: 3,
        display: 'inline-flex',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    };

    const DefaultTopBarButtons = [
        { TopBarButtonName: 'New', TopBarMenuButtonIcon: ButtonPlusSignIcon, },
        { TopBarButtonName: 'Clear All', TopBarMenuButtonIcon: ButtonClearIcon, },
        { TopBarButtonName: 'Delete', TopBarMenuButtonIcon: DeleteIcon, },
    ];

    const [language, setLanguage] = useState(localStorage.getItem('language') || i18n.language);

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n]);

    const handleLanguageChange = (value) => {
        setLanguage(value);
        localStorage.setItem('language', value);
        i18n.changeLanguage(value);
    };

    return (
        <div className="custom-topbar-div-style" >
            <Stack direction="row" spacing={10} wrap justifyContent="space-between" alignItems="flex-start">
                <div>
                    <NavbarBrand elevation={0} className="custom-navbar-brand">
                        <MenuButton
                            classNameMenuButton='main-menu-icon-open'
                            iconButtonSX={slidingAppBar}
                            classNameIconButton='main-menu-icon-button-open'
                            MenuButtonIcon={SideNavDrawerIcon}
                            onButtonClick={SideNavClickHandler}
                        />
                        <Select
                            value={language}
                            onChange={handleLanguageChange}
                            style={{ width: 120 }}
                        >
                            <Option value="en">English</Option>
                            <Option value="zhTW">繁體中文</Option>
                            <Option value="zhCN">简体中文</Option>
                            <Option value="fil">Filipino</Option>
                            <Option value="hi">हिन्दी</Option>
                        </Select>
                    </NavbarBrand>
                </div>
                <div className="custom-topbar-item-searchbar">
                    <Stack spacing={4} direction="row" alignItems="center" justifyContent="flex-end">
                         <Box sx={hideSearchBar} >
                            <MesFilterSearchBarAlt
                                variant='standard'
                                noUnderline={false}
                                iconClassName='custom-main-search-icon'
                                searchBarClassName='custom-main-filter-search-bar'
                            />
                            <MesIconButton Icon={FilterIconOutlined} classNameIconButton='custom-main-filter-icon' />
                        </Box>
                    </Stack>
                </div>
            </Stack>
        </div>
    );
}
