import React from 'react';
import '../../../components/shared/MesCustomStyle.css';
import { Grid, Stack } from '@mui/material';
import { Descriptions } from 'antd';
import { ReactComponent as ConnectorServiceIcon } from '../../../components/shared/images/pageIcons/eapIcon/ConnectorServiceIcon.svg';
import { ReactComponent as RedCircleIcon } from '../../../components/shared/images/pageIcons/eapIcon/RedCircleIcon.svg';
import { ReactComponent as GreenCircleIcon } from '../../../components/shared/images/pageIcons/eapIcon/GreenCircleIcon.svg';
import MesFormTitle from '../../../components/mesTitlePage/MesFormTitle';
import { useTranslation } from 'react-i18next';

export default function ConnectorService({ data }) {
    const { t } = useTranslation();
    return (
        <div className="custom-connectorservice-div">
            <Grid container>
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between">
                        <div>
                            <MesFormTitle
                                TitlePageIcon={ConnectorServiceIcon}
                                classNameTitlePageIcon="custom-service-connectorservice-icon"
                                TitleText={t("CustomService.ConnectorService")}
                                classNameTextTitle="custom-service-connectorservice-titletext"
                                hasSearchBar={false}
                                hasParagraph={false}
                                stackIconAndTextSpacing={2}
                            />
                        </div>
                        <div>
                            <Stack direction="row" spacing={2}>
                                <div>
                                    <MesFormTitle
                                        TitlePageIcon={data.status === "Connected" ? GreenCircleIcon : RedCircleIcon}
                                        classNameTitlePageIcon="custom-service-connectorservice-icon"
                                        TitleText="Connect Status"
                                        classNameTextTitle="custom-service-connectorservice-titletext-stats"
                                        hasSearchBar={false}
                                        hasParagraph={false}
                                        stackIconAndTextSpacing={2}
                                    />
                                </div>
                            </Stack>
                        </div>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Descriptions title="" bordered>
                        <Descriptions.Item label={t("Equipment.ConnectorName")}>{data.connectorName || "N/A"}</Descriptions.Item>
                        <Descriptions.Item label={t("Equipment.CurrentRecipe")}>{data.currentRecipe || "N/A"}</Descriptions.Item>
                        <Descriptions.Item label={t("Equipment.ControlState")}>{data.controlState || "N/A"}</Descriptions.Item>
                        <Descriptions.Item label={t("Equipment.ProcessState")}>{data.processState || "N/A"}</Descriptions.Item>
                        <Descriptions.Item label={t("Equipment.GoodCount")}>{data.goodCount || "N/A"}</Descriptions.Item>
                        <Descriptions.Item label={t("Equipment.DefectiveCount")}>{data.defectiveCount || "N/A"}</Descriptions.Item>
                        <Descriptions.Item label={t("Equipment.MDLN")}>{data.mdln || "N/A"}</Descriptions.Item>
                        <Descriptions.Item label={t("Equipment.SOFTREV")}>{data.softrev || "N/A"}</Descriptions.Item>
                        <Descriptions.Item label={t("Equipment.ID")}>{data.id || "N/A"}</Descriptions.Item>
                        <Descriptions.Item label={t("Equipment.Type")}>{data.type || "N/A"}</Descriptions.Item>
                    </Descriptions>
                </Grid>
            </Grid>
        </div>
    );
}
