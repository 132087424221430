import React, { useState } from "react";
import '../shared/MesCustomStyle.css';
export default function MesVerticalTabFilled({
    currentTab = 0,
    tabs = [
        { id: 0, title: "Tab 1" },
        { id: 1, title: "Tab 2" },
        { id: 2, title: "Tab 3" }
    ],
    currentTabHandler = () => alert("Testing tab"),
    filledTabClassName = 'filled-tab',
    filledTabListClassName = 'filled-tab-list'
}) {

    function Tab({ title, id, currentTab, currentTabHandler, Icon }) {
        return (
            <div
                className={`${filledTabClassName} ${id === currentTab ? "active" : ""}`}
                onClick={() => currentTabHandler(id)}
            >
                <div style={{ display: 'inline-flex' }}>
                    <div style={{ marginRight: '12px' }}>
                        <Icon />
                    </div>
                    {title}
                </div>
            </div>
        );
    }

    return (
        <div className="App">
            <div className={filledTabListClassName} >
                {tabs.map(tab => (
                    <Tab
                        key={tab.id}
                        id={tab.id}
                        title={tab.title}
                        currentTab={currentTab}
                        currentTabHandler={currentTabHandler}
                        Icon={tab.Icon}
                    />
                ))}
            </div>
        </div>
    );
}