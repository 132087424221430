import { call, put, takeEvery } from 'redux-saga/effects';
import * as LogViewerConst from "../const/LogViewerConst";
import * as LogViewerAction from "../actions/LogViewerAction";
import * as LogViewerApi from '../apis/LogViewerApi';

export function* watchLogViewerOnGetDataValues() {
    yield takeEvery(LogViewerConst.GET_LOG_VIEWER_DATA_VALUE, fnLogViewerGetDataValues);
}
export function* watchLogViewerOnGetDataOnce() {
    yield takeEvery(LogViewerConst.GET_LOG_VIEWER_DATA_ONCE, fnLogViewerGetDataOnce);
}

export function* watchLogViewerOnSubmitData() {
    yield takeEvery(LogViewerConst.SUBMIT_LOG_VIEWER_DATA, fnLogViewerSubmitData);
}

export function* watchLogViewerOnStartTime() {
    yield takeEvery(LogViewerConst.SUBMIT_LOG_VIEWER_DATA, fnLogViewerStartTime);
}

export function* watchLogViewerOnStopTime() {
    yield takeEvery(LogViewerConst.SUBMIT_LOG_VIEWER_DATA, fnLogViewerStopTime);
}

export function* watchLogViewerOnDeleteData() {
    yield takeEvery(LogViewerConst.DELETE_LOG_VIEWER_DATA, fnLogViewerDeleteData);
}

export function* watchLogViewerOnUpdateData() {
    yield takeEvery(LogViewerConst.UPDATE_LOG_VIEWER_DATA, fnLogViewerUpdateData);
}

function* fnLogViewerGetDataValues() {
    try {
        var result = yield call(LogViewerApi.getLogViewerData);
        //console.log(result)
        yield put(LogViewerAction.LogViewerSetDataValue(result));
    }
    catch (e) {
        console.log(e)
    }
    finally {
      
    }
}

// Grid Table related functions*

function* fnLogViewerGetDataOnce() {
    try {
        var result = yield call(LogViewerApi.getLogViewerDataOnce);
        // Leads to a GET Method. If fetching all data, no need to add a second argument
        // Otherwise, use a second argument to fetch a single or multiple data
        console.log(result)
        yield put(LogViewerAction.LogViewerSetDataOnce(result));
    }
    catch (e) {
        console.log(e)
    }
    finally {

    }
}

function* fnLogViewerSubmitData(params) {
    try {
        var result = yield call(LogViewerApi.submitLogViewerData, params.payload);
        console.log(result);
        var output = yield call(LogViewerApi.getLogViewerDataOnce);
        yield put(LogViewerAction.LogViewerSetDataOnce(output));
    }
    catch (e) {
        console.log(e)
    }
    finally {

    }
}

function* fnLogViewerStartTime(params) {
    try {
        var result = yield call(LogViewerApi.startLogViewerData, params.payload);
        console.log(result);
        var output = yield call(LogViewerApi.getLogViewerDataOnce);
        yield put(LogViewerAction.LogViewerSetDataOnce(output));
    }
    catch (e) { console.log(e) }
    finally { }
}

function* fnLogViewerStopTime(params) {
    try {
        var result = yield call(LogViewerApi.stopLogViewerData, params.payload);
        console.log(result);
        var output = yield call(LogViewerApi.getLogViewerDataOnce);
        yield put(LogViewerAction.LogViewerSetDataOnce(output));
    }
    catch (e) { console.log(e) }
    finally { }
}

function* fnLogViewerDeleteData(params) {
    try {
        var result = yield call(LogViewerApi.deleteLogViewerData, { "id": params.payload} );
        console.log(result);
        var output = yield call(LogViewerApi.getLogViewerDataOnce);
        yield put(LogViewerAction.LogViewerSetDataOnce(output));
    }
    catch (e) {
        console.log(e)
    }
    finally {

    }
}   

function* fnLogViewerUpdateData(params) {
    try {
        var result = yield call(LogViewerApi.updateLogViewerData, params.payload);
        console.log(result);
        var output = yield call(LogViewerApi.getLogViewerDataOnce);
        yield put(LogViewerAction.LogViewerSetDataOnce(output));
    }
    catch (e) {
        console.log(e)
    }
    finally {

    }
}

// End of Grid Table related functions*