class ConnectorModel {
    constructor() {
        this.id = 0;
        this.name = "";
        this.value = "";
        this.description = "";
        this.type = "";
    }
}

export default ConnectorModel;