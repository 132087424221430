import React, { useEffect, useState } from "react";
import '../shared/MesCustomStyle.css';
import { ReactComponent as DefaultTitlePageIcon } from '../shared/images/defaultIcons/TitlePageDefaultIcon.svg';
import { Grid, Paper, Typography, Box, Stack } from '@mui/material';
import SearchBar from '../../components/mesSearchBar/MesSearchBar';

export default function MesFormTitle({
    hasIcon = true,
    hasParagraph = true,
    hasSearchBar = true,
    TitlePageIcon = DefaultTitlePageIcon,
    classNameTitlePageIcon = 'form-title-icon',
    TitleText = 'Form Name',
    classNameTextTitle = 'form-title-custom-text',
    ParagraphTextField = 'This is subtitle or a paragraph..',
    classNameTextSubTitle = 'custom-form-paragraph-text',
    classNameBox = 'custom-form-title-page-box',
    classNameGrid = 'custom-form-mestitlepage-grid',
    classNameSearchBar = 'custom-mes-search-bar',
    stackIconAndTextSpacing = 0,
}) {

    return (
        <div>
            <Grid container wrap="wrap" spacing={2} className={classNameGrid} >

                <Grid item xs={12}>
                    <Stack direction="row" spacing={stackIconAndTextSpacing} justifyContent="center" alignItems="flex-start">
                        {hasIcon ?
                            <div>
                                <TitlePageIcon className={classNameTitlePageIcon} />
                            </div>
                            : null}
                        <div>
                            <Typography wrap className={classNameTextTitle} >{TitleText}</Typography>
                        </div>
                    </Stack>
                </Grid>
                {hasParagraph ?
                    <Grid item xs={12}>
                        <Typography wrap className={classNameTextSubTitle} >{ParagraphTextField}</Typography>
                    </Grid> : null}
                {hasSearchBar ?
                    <Grid item xs>
                        <SearchBar searchBarClassName={classNameSearchBar} placeholderText={"Search"} />
                    </Grid> : null}
            </Grid>
        </div>
    );
};
