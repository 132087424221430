import React, { useEffect, useState } from "react";
import { ReactComponent as DefaultTitlePageIcon } from '../shared/images/defaultIcons/TitlePageDefaultIcon.svg';
import { Grid, Avatar, Typography, Paper } from '@mui/material';
import RoundFactoryPic from '../shared/images/assets/RoundFactoryPic.jpg';
import Box from '@mui/material/Box';
import '../shared/MesCustomStyle.css';
import Stack from '@mui/material/Stack';

MesTitlePage.defaultProps = {
    hasIcon: true,
    TitlePageIcon: DefaultTitlePageIcon,
    classNameTitlePageIcon: 'title-icon',
    TitleText: 'Module Name',
    classNameTextTitle: 'title-custom-text',
    SubTitleText: 'Submodule Name',
    classNameTextSubTitle: 'sub-title-custom-text',
    classNameBox: 'custom-title-page-box',
    classNameGrid: 'custom-grid-mestitlepage-icon',
    isAvatar: false,
    avatarSrc: RoundFactoryPic,
    classNameAvatar: 'title-avatar-icon'
};

function MesTitlePage({
    TitlePageIcon, classNameTitlePageIcon, TitleText, classNameTextTitle, SubTitleText, classNameTextSubTitle, classNameBox, classNameGrid, hasIcon,
    avatarSrc, classNameAvatar, isAvatar }) {
    return (
        <Box className={classNameBox}>
            <Grid container spacing={0} className={classNameGrid} >
                <Stack direction="row">
                    <div>
                        {hasIcon ?
                            <Stack>
                                {isAvatar ?
                                    <Avatar src={avatarSrc} className={classNameAvatar} /> :
                                    <TitlePageIcon className={classNameTitlePageIcon} />}
                            </Stack> : null}
                    </div>
                    <div>
                        <Stack direction="column">
                            <div>
                                <Typography className={classNameTextTitle} >{TitleText}</Typography>
                            </div>
                            <div>
                                <Typography className={classNameTextSubTitle} >{SubTitleText}</Typography>
                            </div>
                        </Stack>
                    </div>
                </Stack>
            </Grid>
        </Box>
    );
};


export default MesTitlePage;