import React from 'react';
import '../shared/MesCustomStyle.css';
import { TextField, Box, IconButton } from "@mui/material";
import { ReactComponent as SearchIcon } from '../shared/images/assets/serachBarIcon.svg';



function MesFilterSearchBarAlt({
    Icon = SearchIcon,
    variant = 'filled',
    noUnderline = true,
    seachbarIconClickHandler = () => alert('Search Icon Clicked.'),
    onChangeHandler = (e, v) => console.log('Search Bar Test. Add a Change Handler to handle search input.'),
    value = '',
    searchBarClassName = 'mes-search-bar-v2',
    placeholderText = 'Search...',
    iconClassName = 'mes-search-icon',
    iconStartAdornmentSX = {},
    fullWidth = false,
    errorHandler = () => { },
    filterSearchBarSX = {},
}) {

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={seachbarIconClickHandler}>
                <Icon className={iconClassName} style={iconStartAdornmentSX} />
            </IconButton>
            <TextField
                hiddenLabel
                fullWidth={fullWidth}
                id="input-with-icon-adornment"
                className={searchBarClassName}
                error={errorHandler}
                value={value}
                onChange={onChangeHandler}
                variant={variant}
                sx={filterSearchBarSX}
                size='small'
                InputProps={{
                    disableUnderline: noUnderline
                }}
                placeholder={placeholderText}
            />
        </Box>
    );
};

export default MesFilterSearchBarAlt;
