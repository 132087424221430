import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import '../shared/MesCustomStyle.css'
import { ReactComponent as SideNavPartition } from '../shared/images/sideNavIcons/SideNav3dotPartition.svg'
import GeneralSetupSideNavData from '../mesSideBar/MesGeneralSetupSideNavData';

function MesGeneralSetupSideNav({ sideNavWidth, sideNavOpen }) {
    
    const theme = useTheme();

    const openedMixin = (theme) => ({
        width: sideNavWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflow: 'hidden',
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflow: 'hidden',
        width: `calc(${theme.spacing(6)} + 2px)`,
        [theme.breakpoints.up('sm')]: { width: `calc(${theme.spacing(8)} - 14px)`, },
    });

    const slidingDrawer = {
        width: sideNavWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'content-box',
        ...(sideNavOpen && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!sideNavOpen && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }

    const renderHeader = (header) => {
        return (
            sideNavOpen ?
                <h2 className="custom-sidenav-title-general-setup">
                    {header}
                </h2>
                :
                <h2 className="custom-sidenav-partition-general-setup">
                    <SideNavPartition />
                </h2>
        );
    }

    const renderList = (list) => {
        return (
            <List disablePadding>
                {list.map((item, index) => {
                    return <ListItem
                        key={index}
                        className="row"
                        id={window.location.pathname === item.link ? "active" : ""}
                        onClick={() => { window.location.pathname = item.link }}
                        sx={{
                            display: 'block',
                            mb: '12px !important'
                        }}
                        disablePadding
                    >
                        <ListItemButton sx={{
                            minHeight: 48,
                            justifyContent: sideNavOpen ? 'initial' : 'center',
                            p: 0
                        }}>
                            <ListItemIcon sx={{
                                minWidth: 0,
                                p: '15px',
                                justifyContent: 'center',
                                '& svg path': {
                                    fill: window.location.pathname === item.link ? '#F15D22' : '#545454',
                                    stroke: window.location.pathname === item.link ? '#F15D22' : '#545454',
                                    strokeWidth: 0,
                                }
                            }}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText primary={item.title} sx={{
                                py: '15px', my: 0, pl: '12px',
                                opacity: sideNavOpen ? 1 : 0,
                                '& .MuiTypography-root': {
                                    color: window.location.pathname === item.link ? '#002856' : '#454545',
                                    fontWeight: window.location.pathname === item.link ? 600 : 400,
                                    fontSize: '18px'
                                },
                            }} />
                        </ListItemButton>
                    </ListItem>
                })}
            </List>
        );
    }

    return (
        <div className="container-sidenav-div">
            <Box sx={{ direction: "row", position: "fixed" }}>
                <CssBaseline />
                <Grid container spacing={0}>
                    <Drawer
                        className="custom-side-nav-drawer"
                        variant="permanent"
                        open={sideNavOpen}
                        sx={slidingDrawer}
                    >
                        <List className="custom-list">
                            <Box className="custom-sidebar-general-setup" key="0" >
                                <Box sx={{ height: '100px', m: 0 }} key="0" />
                                <Box className="custom-sidebar-list" key="1">
                                    {GeneralSetupSideNavData !== undefined && GeneralSetupSideNavData.length !== 0 ?
                                        GeneralSetupSideNavData.map((generalSetupData, index) => {
                                            return (
                                                <Box label="Render Setup Section" sx={{ marginTop: '16px' }} key={index} >
                                                    {renderHeader(generalSetupData.header)}
                                                    {renderList(generalSetupData.children)}
                                                </Box>
                                            )
                                        })
                                        :
                                        null
                                    }
                                </Box>
                            </Box>
                        </List>
                    </Drawer>
                </Grid>
            </Box>
        </div>
    );
}
MesGeneralSetupSideNav.defaultProps = {
    sideNavWidth: 280,
    sideNavOpen: false
}

export default MesGeneralSetupSideNav;