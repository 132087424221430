import React from 'react';
import {
    GridToolbarQuickFilter,
    GridToolbarFilterButton,
    GridToolbarContainer,
} from '@mui/x-data-grid';
import InputAdornment from "@mui/material/InputAdornment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import { ReactComponent as SearchIcon } from '../shared/images/assets/serachBarIcon.svg';
import { ReactComponent as MoreIcon } from '../shared/images/assets/MoreIcon.svg';
import MesLabelAndIconButton from '../mesButton/MesLabelAndIconButton';
import MesIconButton from '../mesButton/MesIconButton';

export default function MesGridTableDefaultToolbar({
    hideToolbar = false,
    readOnly = false,
    needQuickFilter = true,
    quickFilterComponent = 
        <Grid item sx = {{ display: 'flex', justifyContent: 'space-between' }}>
                <GridToolbarFilterButton
                    disableRipple
                    className='renderFrameDataGrid-ToolbarFilter'
                    sx={{ my: 'auto' }}
                />
                <GridToolbarQuickFilter
                    hiddenLabel
                    id="input-with-icon-adornment"
                    className='mes-search-bar-v2-toolbar'
                    variant="filled"
                    size='small'
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: <InputAdornment className='mes-search-icon' position="start"><SearchIcon style={{ width: '14px', height: '14px' }} /></InputAdornment>,
                    }}
                    placeholder='Search...'
                />
            </Grid >,
    mainButtonList = [],
    needMoreOptions = true,
    menuButtonList = [],
    labelAndIconButtonClassName = 'datagrid-button-size',
    mainMenuClassName = 'toolbar-main-menu',
}) {
 
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => { setAnchorEl(event.currentTarget) };
    const handleClose = () => { setAnchorEl(null) };

    const checkForQuickFilter = (needQuickFilter) => {
        return needQuickFilter ? quickFilterComponent : null

    }

    const checkForMainMenu = (readOnly) => {
        return readOnly !== true &&
            <Grid item className={mainMenuClassName} >
                <>
                    <>
                        {mainButtonList.length > 0 &&
                            mainButtonList.map((mainButton, index) => {
                                return <MesLabelAndIconButton key={index} Icon={mainButton.Icon} classNameButton={labelAndIconButtonClassName} Label={mainButton.Label} OnClickHandler={mainButton.OnClickHandler} />
                            })
                        }
                    </>
                    <>
                        {needMoreOptions &&
                            <MesIconButton key={mainButtonList.length + 1} Icon={MoreIcon} classNameIconFrame='product-spec-icon-button-frame' classNameIconButton='product-spec-icon-button' onButtonClick={handleClick} />
                        }
                    </>
                </>
            </Grid>
    }

    return (
        <GridToolbarContainer className='content-table-header' sx={{ display: !hideToolbar ? 'block' : 'none' }}>
            <Grid container sx={{ display: 'flex', justifyContent: needQuickFilter ? 'space-between' : 'flex-end', alignItems: 'flex-end', flexGrow: 1, }}>
                <div>
                    {checkForQuickFilter(needQuickFilter)}
                </div>
                <div>
                    {checkForMainMenu(readOnly)}
                </div>
            </Grid>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                sx={{
                    '& .MuiList-root.MuiMenu-list': { py: 0, backgroundColor: '#DEDEDE' },
                    '& .MuiButtonBase-root.MuiMenuItem-root': { py: 1, width: '195px', color: '#002856', fontSize: '14px' },
                    '& .MuiPaper-root.MuiPopover-paper.MuiMenu-paper': { boxShadow: '0px 2px 10px 0px rgba(0,0,0,0.16);' },
                }}
            >
                {menuButtonList.length > 0 &&
                    menuButtonList.map((menuItem, index) => {
                        if (index !== menuItem.length - 1) { return <MenuItem key={index} onClick={() => { menuItem.OnClickHandler(); handleClose() }} sx={{ backgroundColor: '#FFFFFF', marginBottom: '1px' }} ><menuItem.Icon style={{ marginRight: '8px' }} /> {menuItem.Label}</MenuItem> }
                        else { return <MenuItem key={index} onClick={() => { menuItem.OnClickHandler(); handleClose() }} sx={{ backgroundColor: '#FFFFFF', }}><menuItem.Icon style={{ marginRight: '8px' }} /> {menuItem.Label}</MenuItem> }
                    })
                }
            </Menu>
            {/*    <MesIconButton aria-label="delete" Icon={SideNavPartition} />*/}
        </GridToolbarContainer>
    );
}