import { combineReducers, createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import _ from "lodash";

import LogViewerReducer from './logViewerUI/reducers/LogViewerReducer';
import LogViewerCombinedSagas from './logViewerUI/LogViewerCombinedSagas';
import SettingReducer from './settingUI/reducers/SettingReducer';
import SettingCombinedSagas from './settingUI/SettingCombinedSagas';
import ConnectorReducer from './connectorUI/reducers/ConnectorReducer';
import ConnectorCombinedSagas from './connectorUI/ConnectorCombinedSagas';


const logger = store => next => action => {
    console.group(action.type)
    console.info('dispatching', action)
    let result = next(action)
    console.log('next state', store.getState())
    console.groupEnd(action.type)
    return result
}

const rootReducers = combineReducers({
    logViewerReducer: LogViewerReducer,
    settingReducer: SettingReducer,
    connectorReducer: ConnectorReducer
});
 
function* rootSaga() {
    yield all([
        LogViewerCombinedSagas(),
        SettingCombinedSagas(),
        ConnectorCombinedSagas(),
    ]);
}

const sagaMiddleware = createSagaMiddleware();

let MesPageStore =
    createStore(
        rootReducers,
        applyMiddleware(
            logger,
            sagaMiddleware
        ));
sagaMiddleware.run(rootSaga);

export default MesPageStore; 