import * as LogViewerConst from "../const/LogViewerConst";
import LogViewerModel from "../models/LogViewerModel";
import { LogViewerDetailData, LogViewerLocalData } from '../data/LogViewerData';

const initialState = {
    LogViewerModel: new LogViewerModel(),
    LogViewerData: LogViewerLocalData,
    LogViewerOptions: LogViewerDetailData,
};


const LogViewerReducer = (state = initialState, action) => {
    switch (action.type) {
        case LogViewerConst.SET_LOG_VIEWER_DATA_VALUE:
            var newStateObj = { ...Object.assign({}, state, { "LogViewerModel": action.payload }) };
            return newStateObj;
        case LogViewerConst.SET_LOG_VIEWER_DATA_ONCE:
            var getCPCDataOnce = { ...Object.assign({}, state, { "LogViewerData": action.payload }) };
            return getCPCDataOnce;
        default:
            return state;
    }
};

export default LogViewerReducer;