import react from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ReactComponent as MonitorIcon } from '../shared/images/mesSlidingFormIcons/monitorIcon.svg';

// Define functions outside the component

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'

}));

export default function SkillSetsWithMonitorIcon({
    skillSetsFilterClassName = 'skill-sets-filter',
    skillSetsClassName = 'skill-sets',
    monitorIconClassName = 'monitor-icon-svg',
}) {
   
    return (
        <StyledToolbar>
            <div className={skillSetsFilterClassName}>
                <div className={skillSetsClassName}>
                    <MonitorIcon className={monitorIconClassName} />
                    <Typography variant="h6" sx={{ color: 'darkblue', fontWeight: 'bold'}}>
                        Skill Sets
                    </Typography>
                </div>
            </div>
        </StyledToolbar>
    );
}
