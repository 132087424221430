import React, { Component, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/shared/MesLayout';
import './custom.css';
import { Provider } from 'react-redux';
import EapStore from './eapPage/EapStore';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

export default class App extends Component {
    static displayName = App.name;

  render() { 
    return (
        <Provider store={EapStore}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Layout>
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                    </Routes>
                </Layout>
            </LocalizationProvider>
        </Provider>
    );
  }
}
