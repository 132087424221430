import * as SettingConst from "../const/SettingConst";

export const SettingSetDataValue = (params) => {

    return {
        type: SettingConst.SET_SETTING_DATA_VALUE,
        payload: params
    };
}

export const SettingGetDataValue = (params) => {

    return {
        type: SettingConst.GET_SETTING_DATA_VALUE,
        payload: params
    };
}

export const SettingGetDataOnce = (params) => {

    return {
        type: SettingConst.GET_SETTING_DATA_ONCE,
        payload: params
    };
}

export const SettingSetDataOnce = (params) => {

    return {
        type: SettingConst.SET_SETTING_DATA_ONCE,
        payload: params
    };
}

export const SettingCreateOrUpdateData = (params) => {

    return {
        type: SettingConst.CREATE_OR_UPDATE_SETTING_DATA,
        payload: params
    };
}

export const SettingDeleteData = (params) => {

    return {
        type: SettingConst.DELETE_SETTING_DATA,
        payload: params
    };
}
