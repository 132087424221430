import React from 'react';
import { Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import MesSliderTitle from '../../components/mesTitlePage/MesSliderTitle';
import { ReactComponent as ConnectorRuntimeSliderIcon } from '../../components/shared/images/eapSliderIcons/ConnectorRuntimeSliderIcon.svg';
import { useTranslation } from 'react-i18next';

const Runtime = ({ connectorRuntime }) => {
    const { t } = useTranslation();

    return (
        <Paper className='content-table' elevation={4} sx={{ width: '100%', padding: '12px' }}>
            <div style={{ marginBottom: '32px' }}>
                <MesSliderTitle classNameSliderGrid='eapConnector-SliderIcon' classNameTitleText='eapConnector-SliderTitle' SliderTitleIcon={ConnectorRuntimeSliderIcon} TitleText={t("CommonString.Runtime")} />
            </div>
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ color: '#686667', fontSize: '18px' }}>{t("CommonString.Field")}</TableCell>
                            <TableCell sx={{ color: '#686667', fontSize: '18px' }}>{t("CommonString.Value")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {connectorRuntime && Object.entries(connectorRuntime).map(([key, value], index) => (
                            <TableRow
                                key={index}
                                sx={{ '& .MuiTableCell-root': { height: '50px' }, '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell sx={{ fontWeight: 'bold', color: '#686667', fontSize: '18px' }}>
                                    {key}
                                </TableCell>
                                <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }}>
                                    {value.toString()}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default Runtime;
