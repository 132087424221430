import React from "react";
import '../shared/MesCustomStyle.css';
import { Grid, Paper, Typography, Box, Stack } from '@mui/material';
import { ReactComponent as DefaultIcon } from '../../components/shared/images/assets/UnionIcon.svg';

export default function MesSliderTitle({
    TitleText = 'Sample Title',
    classNameTitleText = 'custom-slidertitle-text',
    SliderTitleIcon = DefaultIcon,
    classNameSliderTitleIcon = '',
    classNameSliderGrid = 'custom-slidertitle-div',
    hasText = true,
    hasIcon = true,
}) {

    return (
        <div className={classNameSliderGrid}>
            <Stack direction="row" spacing={1}>
                {hasIcon ? <SliderTitleIcon className={classNameSliderTitleIcon} /> : null}
                {hasText ? < Typography className={classNameTitleText}>{TitleText}</Typography> : null}
            </Stack>

        </div>
    );

}
