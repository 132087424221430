import React from 'react';
import { Paper } from '@mui/material';
import MesSliderTitle from '../../components/mesTitlePage/MesSliderTitle';
import MesVerticalTabFilled from '../../components/mesTab/MesVerticalTabFilled';
import { ReactComponent as ConnectorConnectorsSliderIcon } from '../../components/shared/images/eapSliderIcons/ConnectorConnectorsSliderIcon.svg';
import { useTranslation } from 'react-i18next';

const Connectors = ({ connectors, connectorTab, setConnectorTab }) => {
    const { t } = useTranslation();
    return (
        <Paper className='content-table' elevation={4} sx={{ width: '100%', padding: '12px' }}>
            <MesSliderTitle
                classNameSliderGrid='eapConnector-SliderIcon'
                classNameTitleText='eapConnector-SliderTitle'
                SliderTitleIcon={ConnectorConnectorsSliderIcon}
                TitleText={t('Connector.ConnectorList')}
            />
            <MesVerticalTabFilled
                filledTabClassName='eap-filled-tab'
                filledTabListClassName='eap-filled-tab-list'
                currentTab={connectorTab}
                tabs={connectors.map((connector, index) => ({ id: index, title: connector, Icon: () => { } }))}
                currentTabHandler={setConnectorTab}
            />
        </Paper>
    );
};

export default Connectors;
