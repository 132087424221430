import React, { useState, useEffect, useRef } from 'react';
import { Table, Modal, Typography, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Paper, Grid, Stack } from '@mui/material';
import '../../components/shared/MesInputStyle.css';
import MesTitlePage from '../../components/mesTitlePage/MesTitlePage';
import MesDashboard from '../../components/mesDashboard/MesDashboard';
import { ReactComponent as EAPSettingsLogo } from '../../components/shared/images/pageIcons/eapIcon/EAPSettingsLogo.svg';

const { Title } = Typography;
const { Option } = Select;

const LogViewer = () => {
    const { t, i18n } = useTranslation();

    const [filteredLogs, setFilteredLogs] = useState([]);
    const [selectedLog, setSelectedLog] = useState(null);
    const [filterLoggerName, setFilterLoggerName] = useState('SECSService'); // 預設值設為 'SECSService'
    const [filterLogLevel, setFilterLogLevel] = useState('Info'); // 預設值設為 'Info'
    const [pageSize, setPageSize] = useState(10);  // 默认每页显示条数
    const logsRef = useRef([]);
    const wsRef = useRef(null);
    const MessageDashboard = [];
    const DashBoard = [
        <MesDashboard
            classNameDashboardTextContentDiv='custom-message-dashboardtextcontent-dv'
            classNameStack='custom-message-style-box-container'
            classNameDashboard='custom-message-dashboard-box'
            dashboardItems={MessageDashboard} />
    ];

    useEffect(() => {
        fetch('/config.json')
            .then(response => response.json())
            .then(data => {
                const wsUrl = data.apiBaseUrl.replace(/^http/, 'ws');
                wsRef.current = new WebSocket(`${wsUrl}/LogWS`);

                wsRef.current.onmessage = (event) => {
                    const logViewerData = JSON.parse(event.data);
                    const newLog = {
                        key: new Date().getTime(),
                        timestamp: dayjs().format('YYYY-MM-DD HH:mm:ss.SSS'), // 格式化时间戳
                        level: logViewerData.Level,
                        component: logViewerData.ClassName,
                        method: logViewerData.MethodName,
                        message: logViewerData.Message,
                    };

                    logsRef.current = [...logsRef.current, newLog];
                    if (logsRef.current.length > 1000) {
                        logsRef.current = [];
                    }

                    const filtered = logsRef.current.filter(log => {
                        return (
                            (!filterLoggerName || log.component === filterLoggerName) &&
                            (!filterLogLevel || log.level === filterLogLevel)
                        );
                    });
                    setFilteredLogs(filtered);
                };

                wsRef.current.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };

                wsRef.current.onclose = () => {
                    console.log('WebSocket connection closed');
                };
            })
            .catch(error => console.error('Error loading config:', error));

        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, [filterLoggerName, filterLogLevel]);

    useEffect(() => {
        const filtered = logsRef.current.filter(log => {
            return (
                (!filterLoggerName || log.component === filterLoggerName) &&
                (!filterLogLevel || log.level === filterLogLevel)
            );
        });
        setFilteredLogs(filtered);
    }, [filterLoggerName, filterLogLevel]);

    const columns = [
        {
            title: t('LogViewer.Timestamp'),
            dataIndex: 'timestamp',
            key: 'timestamp',
            sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
            width: 200,
        },
        {
            title: t('LogViewer.Level'),
            dataIndex: 'level',
            key: 'level',
            sorter: (a, b) => a.level.localeCompare(b.level),
            width: 150,
        },
        {
            title: t('LogViewer.Component'),
            dataIndex: 'component',
            key: 'component',
            sorter: (a, b) => a.component.localeCompare(b.component),
            width: 200,
        },
        {
            title: t('LogViewer.Method'),
            dataIndex: 'method',
            key: 'method',
            sorter: (a, b) => a.method.localeCompare(b.method),
            width: 200,
        },
        {
            title: t('LogViewer.Message'),
            dataIndex: 'message',
            key: 'message',
            sorter: (a, b) => a.message.localeCompare(b.message),
            width: 300,
            render: (text) => (
                <Tooltip title={text}>
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {text}
                    </div>
                </Tooltip>
            ),
        },
    ];

    return (
        <div style={{ padding: 20 }}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <div>
                            <MesTitlePage
                                TitlePageIcon={EAPSettingsLogo}
                                TitleText={t('CommonString.EquipmentAutomation')}
                                SubTitleText={t('LogViewer.Title')}
                            />
                        </div>
                        <div>
                            {DashBoard}
                        </div>
                    </Stack>
                </Grid>
            </Grid>
            
            <div style={{ marginBottom: 16 }}>
                <Select
                    style={{ width: 200, marginRight: 16 }}
                    placeholder={t('LogViewer.LoggerName')}
                    value={filterLoggerName} // 設定Select的值
                    onChange={(value) => setFilterLoggerName(value)}
                    allowClear
                >
                    {[...new Set(logsRef.current.map(log => log.component))].map(component => (
                        <Option key={component} value={component}>{component}</Option>
                    ))}
                </Select>
                <Select
                    style={{ width: 200 }}
                    placeholder={t('LogViewer.Level')}
                    value={filterLogLevel} // 設定Select的值
                    onChange={(value) => setFilterLogLevel(value)}
                    allowClear
                >
                    {[...new Set(logsRef.current.map(log => log.level))].map(level => (
                        <Option key={level} value={level}>{level}</Option>
                    ))}
                </Select>
            </div>
            <Table
                columns={columns}
                dataSource={filteredLogs}
                pagination={{
                    pageSize: pageSize,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                    onShowSizeChange: (current, size) => setPageSize(size),
                }}
                onRow={(record) => ({
                    onClick: () => {
                        setSelectedLog(record);
                    },
                })}
                scroll={{ x: 'max-content' }} // 启用水平滚动条
            />
            <Modal
                visible={!!selectedLog}
                title={t('LogViewer.Details')}
                onCancel={() => setSelectedLog(null)}
                footer={null}
            >
                {selectedLog && (
                    <div>
                        <p><strong>{t('LogViewer.Timestamp')}:</strong> {selectedLog.timestamp}</p>
                        <p><strong>{t('LogViewer.Level')}:</strong> {selectedLog.level}</p>
                        <p><strong>{t('LogViewer.Component')}:</strong> {selectedLog.component}</p>
                        <p><strong>{t('LogViewer.Method')}:</strong> {selectedLog.method}</p>
                        <p><strong>{t('LogViewer.Message')}:</strong> {selectedLog.message}</p>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default LogViewer;
