import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MesTitlePage from '../../components/mesTitlePage/MesTitlePage';
import * as ConnectorAction from "./actions/ConnectorAction";
import Connectors from './Connectors';
import ConnectorStatus from './ConnectorStatus';
import Configure from './Configure';
import Runtime from './Runtime';
import { ReactComponent as EquipmentAutomationIcon } from '../../components/shared/images/pageIcons/eapIcon/EAPSettingsLogo.svg';
import { useTranslation } from 'react-i18next';

function Connector({ ConnectorData, ConnectorOptions, getConnectorDataOnce, createOrUpdateConnectorData, deleteConnectorData }) {
    const { t } = useTranslation();
    const [config, setConfig] = useState(null);
    const [connectors, setConnectors] = useState([]);
    const [connectorTab, setConnectorTab] = useState(0);
    const [connectorStatus, setConnectorStatus] = useState(null);
    const [connectorConfigure, setConnectorConfigure] = useState(null);
    const [connectorRuntime, setConnectorRuntime] = useState(null);

    // 加載配置文件
    useEffect(() => {
        fetch('/config.json')
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error loading config:', error));
    }, []);

    // 獲取 Connector 列表
    useEffect(() => {
        if (config) {
            axios.get(`${config.apiBaseUrl}/api/GetConnectorList`)
                .then(response => {
                    setConnectors(response.data);
                    if (response.data.length > 0) {
                        setConnectorTab(0); // 預設選擇第一個 Connector
                    }
                })
                .catch(error => {
                    console.error('There was an error fetching the connector list!', error);
                });
        }
    }, [config]);

    // 獲取選擇的 Connector 的數據
    useEffect(() => {
        if (config && connectors.length > 0) {
            const connectorName = connectors[connectorTab];
            axios.post(`${config.apiBaseUrl}/api/GetConnectorConfig`, { connectorName })
                .then(response => {
                    setConnectorConfigure(response.data);
                })
                .catch(error => {
                    console.error('There was an error fetching the connector configure data!', error);
                });

            axios.post(`${config.apiBaseUrl}/api/GetConnectorInfo`, { connectorName })
                .then(response => {
                    setConnectorStatus(response.data);
                })
                .catch(error => {
                    console.error('There was an error fetching the connector status data!', error);
                });

            axios.post(`${config.apiBaseUrl}/api/GetConnectorRuntime`, { connectorName })
                .then(response => {
                    setConnectorRuntime(response.data);
                })
                .catch(error => {
                    console.error('There was an error fetching the connector runtime data!', error);
                });
        }
    }, [config, connectors, connectorTab]);

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} key="1" className='layout-banner'>
                <MesTitlePage
                    classNameBox='layout-banner-box'
                    classNameTitlePageIcon='layout-banner-icon-white'
                    TitlePageIcon={EquipmentAutomationIcon}
                    TitleText={t('CommonString.EquipmentAutomation')}
                    SubTitleText={t('Connector.Title')}
                />
            </Grid>
            <Grid item xs={12} key="2" className='content-container'>
                <Grid container spacing={2}>
                    <Grid item key="2a" xs={2}>
                        <Connectors connectors={connectors} connectorTab={connectorTab} setConnectorTab={setConnectorTab} />
                    </Grid>
                    <Grid item key="2b" xs={5}>
                        {connectorStatus && connectorRuntime && (
                            <>
                                <ConnectorStatus connectorStatus={connectorStatus} connectorRuntime={connectorRuntime} />
                                <br />
                                <Configure connectorConfigure={connectorConfigure} />
                            </>
                        )}
                    </Grid>
                    <Grid item key="2c" xs={5}>
                        {connectorRuntime && <Runtime connectorRuntime={connectorRuntime} />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state, ownProps) => {
    var ConnectorData = state.settingReducer.ConnectorData;
    var ConnectorOptions = state.settingReducer.ConnectorOptions;
    return {
        ConnectorModel: { ...Object.assign({}, {}, ConnectorData) }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getConnectorDataOnce: (state) => dispatch(ConnectorAction.ConnectorGetDataOnce(state)),
        createOrUpdateConnectorData: (state) => dispatch(ConnectorAction.ConnectorCreateOrUpdateData(state)),
        deleteConnectorData: (state) => dispatch(ConnectorAction.ConnectorDeleteData(state)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Connector);
