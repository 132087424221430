import React, { useState } from 'react';
import { Table } from 'antd';

export default function Report({ data }) {
    const [pageSize, setPageSize] = useState(10);
    const columns = [
        { title: 'RPTID', dataIndex: 'rptid', key: 'rptid', render: text => text || 'N/A' },
        { title: 'Alias', dataIndex: 'alias', key: 'alias', render: text => text || 'N/A' },
        { title: 'RPTID Format', dataIndex: 'rptidFormat', key: 'rptidFormat', render: text => text || 'N/A' },
        { title: 'Description', dataIndex: 'description', key: 'description', render: text => text || 'N/A' },
    ];

    const dataSource = Object.keys(data).map(key => ({
        key,
        ...data[key],
    }));
    const handleTableChange = (pagination) => {
        setPageSize(pagination.pageSize);
    };
    return (
        <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{ 
                pageSize: pageSize, 
                showSizeChanger: true, 
                pageSizeOptions: ['10', '20', '50', '100'] 
            }}
            onChange={handleTableChange}
            title={() => ''}
            bordered
        />
    );
}
