import React from 'react';
import '../shared/MesInputStyle.css';
import Button from '@mui/material/Button';
import { ReactComponent as DefaultIcon } from '../../components/shared/images/assets/UnionIcon.svg';


const checkDefaultButtonClick = () => {
    // Your logic for handling the button click goes here
    alert('Text Button clicked');
};

export default function MesMenuLayoutButton({
    Label = 'Test',
    ClassNameButton = 'cantierButtonCancel',
    ClassNameText = 'cantierTextButtonLabel',
    ClassNameIcon = 'cantierButtonIcon',
    showIcon = false,
    Icon = <DefaultIcon />,
    OnClickHandler = checkDefaultButtonClick,
    hasIcon = true,
    isStartIcon = true
}) {

    return (
        <Button disableElevation disableRipple className={ClassNameButton} aria-label="Text Button" onClick={OnClickHandler}>
            {hasIcon ?
                <>
                    <span className={ClassNameText}>{Icon}</span>
                    <span className={ClassNameText}>{Label}</span>
                </>
                : 
                <span className={ClassNameText}>{Label}</span>
            }
        </Button>
    );
}
