import React, { useState } from 'react';
import '../shared/MesCustomStyle.css';
import { Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Grid, Stack } from '@mui/material';
import DefaultHeaderButton from '../mesButton/MesIconButton';
import MenuButton from '../mesButton/MesMenuButton';
import HeaderTitle from '../mesHeaderTitle/MesHeaderTitle'
import { ReactComponent as UserPictureIconHeader } from '../shared/images/defaultIcons/UserProfileDefaultIcon.svg';

import UserAccountIconButton from '../mesAccountMenu/MesUserAccountMenuButton';
import MesMenuLayout from '../shared/MesMenuLayout';


export default function MesHeader({
    HeaderButton = DefaultHeaderButton,
    DivStyle = "custom-header-div-style",
    ItemDivStyle = "custom-header-item-div-style",
    handleLogout = () => { }
}) {
    const [mesDrawerOpen, setMesDrawerOpen] = React.useState(false);

    return (
        <div className={DivStyle} >
            <Stack direction="row" spacing={10} justifyContent="space-between" alignItems="flex-start">
                <div>
                    <NavbarBrand elevation={0} tag={Link} className="custom-navbar-brand">
                        <MenuButton onButtonClick={() => setMesDrawerOpen(true)} />
                        <HeaderTitle />
                    </NavbarBrand>
                </div>
                <div>
                    <Stack spacing={4} direction="row" alignItems="center" justifyContent="flex-end">
                        <div className={ItemDivStyle}>
                            <HeaderButton />
                            <UserAccountIconButton aria-label="User Profile" classNameIcon="user-profile-picture" Icon={UserPictureIconHeader} TooltipTitle={"Profile Account"}
                                MenuItem1={"Profile"} MenuItem2={"My Account"} MenuItem3={"Add Another Account"} MenuItem4={"Settings"} MenuItem5={"Logout"}
                                handleLogout={handleLogout}
                            />
                        </div>
                    </Stack>
                </div>
            </Stack>
            <MesMenuLayout MesDrawerOpen={mesDrawerOpen} OnCloseHandler={() => setMesDrawerOpen(false)} OnOpenHandler={() => setMesDrawerOpen(true)} />
        </div>
    );
}
