import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Paper, Grid, Stack } from '@mui/material';
import '../../components/shared/MesInputStyle.css';
import MesTitlePage from '../../components/mesTitlePage/MesTitlePage';
import MesDashboard from '../../components/mesDashboard/MesDashboard';
import MesGridTable from '../../components/mesGridTable/MesGridTable';
import { ReactComponent as EAPSettingsLogo } from '../../components/shared/images/pageIcons/eapIcon/EAPSettingsLogo.svg';
import { ReactComponent as EAPMaxConcurrencyIcon } from '../../components/shared/images/pageIcons/eapIcon/EAPMaxConcurrencyIcon.svg';
import { ReactComponent as EAPAGVConcurrencyIcon } from '../../components/shared/images/pageIcons/eapIcon/EAPAGVConcurrencyIcon.svg';
import { useTranslation } from 'react-i18next';

export default function Message(props) {
    const { t } = useTranslation();
    const [maxConcurrency, setMaxConcurrency] = useState(0);
    const [agvConcurrency, setAGVConcurrency] = useState(0);
    const [messageData, setMessageData] = useState([]);
    const [config, setConfig] = useState(null);

    // 加载配置文件
    useEffect(() => {
        fetch('/config.json')
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error loading config:', error));
    }, []);

    // 使用配置文件中的URL进行API调用
    useEffect(() => {
        if (config) {
            axios.get(`${config.apiBaseUrl}/api/GetMessageMap`)
                .then(response => {
                    const messageDetails = response.data.messageDetails;
                    setMessageData(messageDetails);

                    const maxConcurrencyValue = Math.max(...messageDetails.map(detail => detail.maximum)); // 最大併發數為訊息最大耗時
                    const totalConcurrency = messageDetails.reduce((acc, cur) => acc + cur.count, 0);
                    const avgConcurrencyValue = (totalConcurrency / messageDetails.length).toFixed(1); // 平均並發數並四捨五入到小數點後一位

                    setMaxConcurrency(maxConcurrencyValue);
                    setAGVConcurrency(avgConcurrencyValue);
                })
                .catch(error => {
                    console.error('There was an error fetching the message details!', error);
                });
        }
    }, [config]);

    const MessageDashboard = [
        { icon: <EAPMaxConcurrencyIcon />, label: t('MessageMapping.MAXConcurrency'), amount: maxConcurrency, dashboardIconClassName: 'product-spec-dashboard-icon lotListWIP' },
        { icon: <EAPAGVConcurrencyIcon />, label: t('MessageMapping.AVGConcurrency'), amount: agvConcurrency, dashboardIconClassName: 'product-spec-dashboard-icon lotListNotStarted' },
    ];

    const DashBoard = [
        <MesDashboard
            classNameDashboardTextContentDiv='custom-message-dashboardtextcontent-dv'
            classNameStack='custom-message-style-box-container'
            classNameDashboard='custom-message-dashboard-box'
            dashboardItems={MessageDashboard} />
    ];

    const MessageColumns = [
        { field: 'message', headerName: t('MessageMapping.Message'), flex: 1 },
        { field: 'service', headerName: t('MessageMapping.Service'), flex: 1 },
        { field: 'method', headerName: t('MessageMapping.Method'), flex: 1 },
        { field: 'state', headerName: t('MessageMapping.State'), flex: 1 },
        { field: 'count', headerName: t('MessageMapping.Count'), flex: 1 },
        { field: 'minimum', headerName: t('MessageMapping.Minimum'), flex: 1 },
        { field: 'maximum', headerName: t('MessageMapping.Maximum'), flex: 1 },
    ];

    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <div>
                            <MesTitlePage
                                TitlePageIcon={EAPSettingsLogo}
                                TitleText={t('CommonString.EquipmentAutomation')}
                                SubTitleText={t('CommonString.Message')}
                            />
                        </div>
                        <div>
                            {DashBoard}
                        </div>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Paper className="custom-message-table-paper">
                        <MesGridTable
                            slotProps={{
                                toolbar: { needMoreOptions: false }
                            }}
                            columns={MessageColumns}
                            rows={messageData}
                            checkboxSelection={false}
                            rowEditMode="row"
                            getRowIdHandler={(row) => row.message}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
