import React from 'react';
import '../shared/MesCustomStyle.css';
import { TextField, InputAdornment } from "@mui/material";
import { ReactComponent as SearchIcon } from '../shared/images/assets/serachBarIcon.svg';
import '../shared/MesCustomStyle.css';


export default function MesSearchBar({
    Icon = SearchIcon,
    searchBarClassName = 'mes-search-bar-v2',
    placeholderText = 'Search...',
    iconClassName = 'mes-search-icon'
}) {

    return (
        <>
            <TextField wrap
                hiddenLabel
                id="input-with-icon-adornment"
                className={searchBarClassName}
                variant="filled"
                size='small'
                InputProps={{
                    disableUnderline: true,
                    startAdornment: <InputAdornment className={iconClassName} position="start"><Icon /></InputAdornment>,
                }}
                placeholder={placeholderText}
            />
        </>
    );
};

