import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import zhTW from './locales/zhtw.json';
import zhCN from './locales/zhcn.json';
import fil from './locales/fil.json';
import hi from './locales/hi.json';

// 初始化i18n实例
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    zhTW: {
      translation: zhTW,
    },
    zhCN: {
      translation: zhCN,
    },
    fil: {
      translation: fil,
    },
    hi: {
      translation: hi,
    },
  },
  lng: 'en', // 默认语言
  fallbackLng: 'en', // 回退语言
  interpolation: {
    escapeValue: false, // React已经安全处理输出
  },
});

export default i18n;
