import { call, put, takeEvery } from 'redux-saga/effects';
import * as ConnectorConst from "../const/ConnectorConst";
import * as ConnectorAction from "../actions/ConnectorAction";
import * as ConnectorApi from '../apis/ConnectorApi';

export function* watchConnectorOnGetDataValues() {
    yield takeEvery(ConnectorConst.GET_CONNECTOR_DATA_VALUE, fnConnectorGetDataValues);
}
export function* watchConnectorOnGetDataOnce() {
    yield takeEvery(ConnectorConst.GET_CONNECTOR_DATA_ONCE, fnConnectorGetDataOnce);
}

export function* watchConnectorOnSubmitOrUpdateData() {
    yield takeEvery(ConnectorConst.CREATE_OR_UPDATE_CONNECTOR_DATA, fnConnectorCreateOrUpdateData);
}

export function* watchConnectorOnDeleteData() {
    yield takeEvery(ConnectorConst.DELETE_CONNECTOR_DATA, fnConnectorDeleteData);
}

function* fnConnectorGetDataValues() {
    try {
        var result = yield call(ConnectorApi.getConnectorData);
        //console.log(result)
        yield put(ConnectorAction.ConnectorSetDataValue(result));
    }
    catch (e) {
        console.log(e)
    }
    finally {
      
    }
}

// Grid Table related functions*

function* fnConnectorGetDataOnce() {
    try {
        var result = yield call(ConnectorApi.getConnectorDataOnce);
        // Leads to a GET Method. If fetching all data, no need to add a second argument
        // Otherwise, use a second argument to fetch a single or multiple data
        console.log(result)
        yield put(ConnectorAction.ConnectorSetDataOnce(result));
    }
    catch (e) {
        console.log(e)
    }
    finally {

    }
}

function* fnConnectorCreateOrUpdateData(params) {
    try {
        var result = yield call(ConnectorApi.createOrUpdateConnectorData, params.payload);
        console.log(result);
        var output = yield call(ConnectorApi.getConnectorDataOnce);
        yield put(ConnectorAction.ConnectorSetDataOnce(output));
    }
    catch (e) {
        console.log(e)
    }
    finally {

    }
}

function* fnConnectorDeleteData(params) {
    try {
        var result = yield call(ConnectorApi.deleteConnectorData, { "id": params.payload} );
        console.log(result);
        var output = yield call(ConnectorApi.getConnectorDataOnce);
        yield put(ConnectorAction.ConnectorSetDataOnce(output));
    }
    catch (e) {
        console.log(e)
    }
    finally {

    }
}   

// End of Grid Table related functions*