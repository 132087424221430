import React from 'react';
import '../shared/MesCustomStyle.css';
import { IconButton, Button, Box } from '@mui/material';
import { ReactComponent as DefaultIcon } from '../shared/images/defaultIcons/HeaderDefaultIcon.svg';

const checkDefaultButtonClick = () => {
    // Your logic for handling the button click goes here
    alert('Icon Button clicked');
};


export default function MesIconButton({
    Icon = DefaultIcon,
    classNameIconFrame = 'main-sidenav-icon-button',
    classNameIconButton = 'custom-default-button-icon',
    onButtonClick = checkDefaultButtonClick,
    isIconButton = true
}) {

    return (

        isIconButton ?
            <IconButton elevation={0}
                aria-label="Icon Button"
                className={classNameIconFrame}
                onClick={onButtonClick} disableRipple={true} >
                <Icon className={classNameIconButton} />
            </IconButton > :
            <Button className={classNameIconFrame} disableRipple={true}>
                <Icon className={classNameIconButton} />
            </Button>


    );
};

