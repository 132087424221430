import React from 'react';
import { Paper, Grid, Typography } from '@mui/material';
import MesSliderTitle from '../../components/mesTitlePage/MesSliderTitle';
import { ReactComponent as ConnectorStatusSliderIcon } from '../../components/shared/images/eapSliderIcons/ConnectorStatusSliderIcon.svg';
import { ReactComponent as ConnectorDashboardIcon } from '../../components/shared/images/eapSliderIcons/ConnectorDashboardIcon.svg';
import { useTranslation } from 'react-i18next';

const ConnectorStatus = ({ connectorStatus }) => {
    const { t } = useTranslation();

    const readOnlyLabels = [
        t("Connector.ConnectState"),
        t("Connector.Status"),
        t("Connector.Name"),
        t("Connector.ComponentName"),
        t("Connector.ComponentVersion"),
        t("Connector.ConfigFilePath"),
        t("Connector.FormatFilePath")
    ];
    const readOnlyStatus = [
        connectorStatus.connectState,
        connectorStatus.status,
        connectorStatus.name,
        connectorStatus.dlL_Name,
        connectorStatus.dlL_Version,
        connectorStatus.configFilePath,
        connectorStatus.formatFilePath
    ];

    return (
        <Paper className='content-table' elevation={4} sx={{ width: '100%', padding: '12px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '40px' }}>
                <MesSliderTitle classNameSliderGrid='eapConnector-SliderIcon' classNameTitleText='eapConnector-SliderTitle' SliderTitleIcon={ConnectorStatusSliderIcon} TitleText={t("Connector.ConnectorStatus")} />
                <div className="dashboard-icon">
                    <ConnectorDashboardIcon />
                </div>
            </div>
            <Grid container>
                {readOnlyLabels.map((label, index) => (
                    <Grid key={index} item xs={6} sx={{ marginBottom: '32px', padding: '8px', '& .MuiTypography-root': { wordBreak: 'break-word' } }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>{t(label)}:</Typography>
                        <Typography sx={{ fontSize: '20px' }}>{readOnlyStatus[index]}</Typography>
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
};

export default ConnectorStatus;
