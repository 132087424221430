class LogViewerModel {
    constructor() {
        this.id = 0;
        this.startDateTime = "";
        this.equipment = "";
        this.endDateTime = "";
        this.employee = "";
        this.status = "";
    }
}

export default LogViewerModel;