import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faPlug, faCog, faTools, faCommentDots } from '@fortawesome/free-solid-svg-icons';
//import { useTranslation } from 'react-i18next';

//const { t } = useTranslation();
const GeneralSetupSideNavData = [
    {
        header: "IIoT",
        children: [
            {
                title: "Log Viewer",
                icon: <FontAwesomeIcon icon={faFileAlt} />,
                link: "/log-viewer"
            },
            {
                title: "Connector",
                icon: <FontAwesomeIcon icon={faPlug} />,
                link: "/connector"
            },
            {
                title: "Setting",
                icon: <FontAwesomeIcon icon={faCog} />,
                link: "/setting"
            },
            {
                title: "Service",
                icon: <FontAwesomeIcon icon={faTools} />,
                link: "/service"
            },
            {
                title: "Message",
                icon: <FontAwesomeIcon icon={faCommentDots} />,
                link: "/message"
            }
        ]
    }
];

export default GeneralSetupSideNavData;
