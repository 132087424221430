import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import '../shared/MesCustomStyle.css';
import './MesInputStyle.css';
import MesHeader from '../mesHeader/MesHeader';
import { useTheme, createStyles } from '@mui/material/styles';
import { Grid, Paper, Stack } from '@mui/material';
import { styled } from '@mui/system';
import AppBar from '@mui/material/AppBar';
import MesTopBar from '../mesTopBar/MesTopBar';
import MesSideNav from '../mesSideNav/MesSideNav';
import IndexButtons from '../mesButton/MesIndexButton';
import MesIconButton from '../mesButton/MesIconButton';
import { ReactComponent as HeaderIconSettings } from '../shared/images/headerIcons/HeaderSettingsIcon.svg';
import { ReactComponent as HeaderIconNotif } from '../shared/images/headerIcons/HeaderNotificationIcon.svg';
import { ReactComponent as InfoIcon } from '../shared/images/headerIcons/HeaderInfoIcon.svg';
import { ReactComponent as TrashIcon } from '../../components/shared/images/indexButtonIcons/TrashIcon.svg';
import { ReactComponent as ShareIcon } from '../../components/shared/images/indexButtonIcons/ShareIcon.svg';
import { ReactComponent as AddIcon } from '../../components/shared/images/indexButtonIcons/AddIcon.svg';
import MesSlidingForm from '../mesSlidingForm/MesSlidingFormTable';
import MesMenuLayout from './MesMenuLayout';
import MesGeneralSetupSideNav from '../mesSideNav/MesGeneralSetupSideNav';

const useStyles = styled((theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
        root: {
            flexGrow: 1,
        },
    }),
);

const MesButtons = () => {
    return [
        <MesIconButton Icon={InfoIcon} Label='Information' classNameIconButton='info-default-logo' key="1" />,
        <MesIconButton Icon={HeaderIconNotif} key='2' />,
        <MesIconButton Icon={HeaderIconSettings} key='3' />

        , /*<MesIconButton key='4' />*/
    ]
};

function MesLayout(props) {

    const { handleLogout, children } = props;

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const theme = useTheme();

    const drawerWidth = [233, 280];

    const MesLayoutClasses = {
        indexButtonBlock: 'indexButtonBlock',
        customMesLayout: 'custom-mes-layout'
    }

    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const handleMouseOver = () => {
        // Handle mouse over event here
        // For example, change some state or perform an action
    };

    const handleMouseOut = () => {
        // Handle mouse out event here
        // For example, change some state or perform an action
    };

    const openDrawer = () => {
        setDrawerOpen(true);
        // Use this function to add open drawer when clicking a component that wants to open a Slider
    }

    const IndexButtonMenu = () => { // Put Index Buttons Here. Add Buttons arranging from left to right floating right side.
        return [
            <IndexButtons Icon={TrashIcon} Label='Delete' key="1" />,
            <IndexButtons Icon={ShareIcon} Label='Export' key="2" />,
            <IndexButtons Icon={AddIcon} Label='Add' frameClassName='indexButtonDivAddV2' iconClassName='renderIconAdd' OnClickHandler={openDrawer} key="3" />]
    }

    const openedMixin = (theme) => ({
        paddingLeft: `${drawerWidth[1]}px`,
        transition: theme.transitions.create('padding-left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('padding-left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        paddingLeft: `50px`,
    });

    const sizeableContent = {
        paddingLeft: drawerWidth[1],
        ...(sidebarOpen && {
            ...openedMixin(theme),
        }),
        ...(!sidebarOpen && {
            ...closedMixin(theme),
        }),
    }

    return (
        <div className={MesLayoutClasses.customMesLayout}>
            <Grid container spacing={0} marginBottom="100px">
                <Grid item xs={12}>
                    <AppBar position="fixed" elevation={0} color="inherit">
                        <AppBar position="relative" elevation={0} color="inherit">
                            <Stack direction="column" spacing={0}>
                                <div>
                                    <MesHeader HeaderButton={MesButtons} handleLogout={props.handleLogout} />
                                </div>
                                <div>
                                    <MesTopBar SideNavClickHandler={toggleSidebar} SideNavOpen={sidebarOpen} SideNavWidth={drawerWidth[1]} />
                                </div>
                            </Stack>
                        </AppBar>
                    </AppBar>
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <MesGeneralSetupSideNav sideNavOpen={sidebarOpen} sideNavWidth={drawerWidth[1]} />
                    {/*<MesSideNav sideNavOpen={sidebarOpen} sideNavWidth={drawerWidth[0]} />*/}
                </Grid>
            </Grid>
            <Grid container spacing={0} id="main" sx={sizeableContent}>
                <Grid item xs={12}>
                    {children}
                </Grid>
            </Grid>
        </div>
    );
}

export default MesLayout;