export const SET_LOG_VIEWER_DATA_VALUE = "SET_LOG_VIEWER_DATA_VALUE";
export const GET_LOG_VIEWER_DATA_VALUE = "GET_LOG_VIEWER_DATA_VALUE";
export const GET_LOG_VIEWER_DATA_ONCE = "GET_LOG_VIEWER_DATA_ONCE";
export const SET_LOG_VIEWER_DATA_ONCE = "SET_LOG_VIEWER_DATA_ONCE";
export const SUBMIT_LOG_VIEWER_DATA = "SUBMIT_LOG_VIEWER_DATA";
export const START_LOG_VIEWER_DATA = "START_LOG_VIEWER_DATA";
export const STOP_LOG_VIEWER_DATA = "STOP_LOG_VIEWER_DATA";
export const DELETE_LOG_VIEWER_DATA = "DELETE_LOG_VIEWER_DATA";
export const UPDATE_LOG_VIEWER_DATA = "UPDATE_LOG_VIEWER_DATA";

// Non-Saga
export const SET_DRAWER_OPEN = "SET_DRAWER_OPEN";