import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ReactComponent as PopupClose } from './images/assets/PopupClose.svg';
import MesMenuLayoutButton from '../mesButton/MesMenuLayoutButton';
import mesMainMenuLayoutData from '../mesMainMenu/MesMainMenuLayoutData';
import './MesCustomStyle.css';

export default function MesMenuLayout({ MesDrawerOpen, OnCloseHandler, OnOpenHandler }) {
    const [selectedMenu, setSelectedMenu] = useState('');
    const loc = useLocation();
    const history = useNavigate();

    useEffect(() => {
        if (MesDrawerOpen) {
            console.log(loc, 'History')
            var selectedMenuItem = mesMainMenuLayoutData.find(item => item.link === loc.pathname);
            if (selectedMenuItem) setSelectedMenu(selectedMenuItem.link)
        }
    }, [history]);

    const MesMenuList = () => {
        return (
            <List className='MesMenuLayout-list'>
                {mesMainMenuLayoutData.map((text, index) => (
                    <ListItem key={text.title} disablePadding>
                        <MesMenuLayoutButton hasIcon={true} Label={text.title}
                            Icon={text.icon} OnClickHandler={() => history(text.link)}
                            ClassNameButton={selectedMenu === text.link ? 'MesMenuButton-active' : 'MesMenuButton-base'}
                            ClassNameText={selectedMenu === text.link ? 'MesMenuButton-text-active' : 'MesMenuButton-text'}
                        />
                    </ListItem>
                ))}
            </List>
        );
    }

    return (
        <React.Fragment key='left'>
            <SwipeableDrawer
                anchor='left'
                open={MesDrawerOpen}
                onOpen={OnOpenHandler}
                onClose={OnCloseHandler}
                className='MesMenuLayout-root'
                swipeAreaWidth={12}
            >
                <div style={{display: 'inline-flex'}} >
                    <IconButton onClick={OnCloseHandler} aria-label="close button" sx={{ width: "24px", height: "24px", ml: 'auto', mr: 0.5, mt: 1, p: 0 }} >
                        <PopupClose className='MesClose' />
                    </IconButton>
                </div>
                <MesMenuList />
                </SwipeableDrawer>
            </React.Fragment>
    );
}