import Checkbox from '@mui/material/Checkbox';
import { ReactComponent as CheckBoxSelectedIcon } from '../shared/images/assets/CheckBoxSelectedIcon.svg';
import { ReactComponent as CheckBoxNotSelectedIcon } from '../shared/images/assets/CheckboxNotSelectedIcon.svg';
import { ReactComponent as HideIndeterminateIcon } from '../../components/shared/images/assets/HideIndeterminateIcon.svg';
export default function MesCustomCheckBox (props) {
    return <Checkbox 
        {...props}
        /*indeterminate={props.Indeterminate}*/
        indeterminateIcon={<HideIndeterminateIcon /> }
        sx={{
            '&.MuiButtonBase-root.MuiCheckbox-root:hover': {
                borderRadius: '6px',
            }
        }}
        icon={<CheckBoxNotSelectedIcon/>} 
        checkedIcon={<CheckBoxSelectedIcon/>} 
    />;
}