import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Paper, Grid, Stack, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import '../../components/shared/MesInputStyle.css';
import MesTitlePage from '../../components/mesTitlePage/MesTitlePage';
import MesTab from '../../components/mesTab/MesTab';
import ConnectorService from './forms/ConnectorService';
import Variable from './forms/Variable';
import Report from './forms/Report';
import Event from './forms/Event';
import PrimaryMessage from './forms/PrimaryMessage';
import { ReactComponent as EAPSettingsLogo } from '../../components/shared/images/pageIcons/eapIcon/EAPSettingsLogo.svg';
import { useTranslation } from 'react-i18next';

export default function Service(props) {
    const { t } = useTranslation();
    const [value, setValue] = useState(0);
    const [equipmentList, setEquipmentList] = useState([]);
    const [selectedEquipment, setSelectedEquipment] = useState('');
    const [config, setConfig] = useState(null);
    const [connectorServiceData, setConnectorServiceData] = useState({});
    const [variableData, setVariableData] = useState({});
    const [reportData, setReportData] = useState({});
    const [eventData, setEventData] = useState({});

    useEffect(() => {
        fetch('/config.json')
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error loading config:', error));
    }, []);

    useEffect(() => {
        if (config) {
            axios.get(`${config.apiBaseUrl}/api/GetEquipmentList`)
                .then(response => {
                    const equipments = response.data;
                    setEquipmentList(equipments);
                    if (equipments.length > 0) {
                        setSelectedEquipment(equipments[0]);  // 預設選擇第一台設備
                    }
                })
                .catch(error => {
                    console.error('There was an error fetching the equipment list!', error);
                });
        }
    }, [config]);

    useEffect(() => {
        if (config && selectedEquipment) {
            axios.post(`${config.apiBaseUrl}/api/GetEquipmentData`, { EquipmentName: selectedEquipment })
                .then(response => {
                    const data = response.data;
                    setConnectorServiceData({
                        id: data.id,
                        type: data.type,
                        status: data.status,
                        currentRecipe: data.currentRecipe,
                        mdln: data.mdln,
                        softrev: data.softrev,
                        connectorName: data.connectorName,
                        controlState: data.controlState,
                        processState: data.processState,
                        goodCount: data.goodCount,
                        defectiveCount: data.defectiveCount,
                    });
                    setVariableData(data.variables);
                    setReportData(data.reports);
                    setEventData(data.events);
                })
                .catch(error => {
                    console.error('There was an error fetching the equipment data!', error);
                });
        }
    }, [config, selectedEquipment]);

    const serviceTabs = [
        t("CustomService.ConnectorService"),
        t("CustomService.Variable"),
        t("CustomService.Report"),
        t("CustomService.Event"),
        t("CustomService.PrimaryMessage"),
    ];

    const formContent = [
        <ConnectorService data={connectorServiceData} />,
        <Variable data={variableData} />,
        <Report data={reportData} />,
        <Event data={eventData} />,
        <PrimaryMessage selectedEquipment={selectedEquipment} equipmentStatus={connectorServiceData.status} />, // 傳遞選擇的設備和設備狀態
    ];

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleEquipmentChange = (event) => {
        setSelectedEquipment(event.target.value);
    };

    return (
        <div>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <div>
                            <MesTitlePage
                                TitlePageIcon={EAPSettingsLogo}
                                TitleText={t('CommonString.EquipmentAutomation')}
                                SubTitleText={t('CustomService.Title')}
                            />
                        </div>
                        <FormControl variant="outlined" style={{ minWidth: 200 }}>
                            <InputLabel id="select-equipment-label">{t("CustomService.EquipmentList")}</InputLabel>
                            <Select
                                labelId="select-equipment-label"
                                id="select-equipment"
                                value={selectedEquipment}
                                onChange={handleEquipmentChange}
                                label={t("CustomService.SelectEquipment")}
                            >
                                {equipmentList.map((equipment, index) => (
                                    <MenuItem key={index} value={equipment}>
                                        {equipment}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div className="custom-service-tab-div">
                            <MesTab
                                value={value}
                                handleChange={handleTabChange}
                                tabTitle={serviceTabs}
                            />
                        </div>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Paper className="custom-service-content-paper">
                        <div>
                            {formContent[value]}
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
}
