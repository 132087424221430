import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material/';

import '../../components/shared/MesInputStyle.css';
import { ReactComponent as EquipmentAutomationIcon } from '../../components/shared/images/pageIcons/EquipmentAutomationIcon.svg';
import { ReactComponent as SettingDatabaseListSliderIcon } from '../../components/shared/images/eapSliderIcons/SettingDatabaseListSliderIcon.svg';
import { ReactComponent as SettingDatabaseManagerSliderIcon } from '../../components/shared/images/eapSliderIcons/SettingDatabaseManagerSliderIcon.svg';
import MesTitlePage from '../../components/mesTitlePage/MesTitlePage';
import * as SettingAction from "./actions/SettingAction";
import MesTab from '../../components/mesTab/MesTab';
import MesSliderTitle from '../../components/mesTitlePage/MesSliderTitle';
import MesVerticalTabFilled from '../../components/mesTab/MesVerticalTabFilled';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

function Setting({ SettingData, SettingOptions, getSettingDataOnce, createOrUpdateSettingData, deleteSettingData }) {
    const { t } = useTranslation();

    const [config, setConfig] = useState(null);

    useEffect(() => {
        fetch('/config.json')
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error loading config:', error));
    }, []);

    /* Data Grid */
    const [manager, setManager] = useState(1); // Local Data, use backend to retrieve rows
    const managers = [t('Setting.License Manager'), t('Setting.Constant Manager'), t('Setting.Parameter Manager'), t('Setting.Database Manager')]; // Local Data, use backend to retrieve rows

    // License Manager
    const [licenseManager, setLicenseManager] = useState(null);

    useEffect(() => {
        if (config) {
            axios.get(`${config.apiBaseUrl}/api/GetLicenseManager`)
                .then(response => {
                    setLicenseManager(response.data);
                })
                .catch(error => {
                    console.error('There was an error fetching the license manager data!', error);
                });
        }
    }, [config]);
    const licenseManagerLabels = [
        t('licenseManager.uuid'), 
        t('licenseManager.userID'), 
        t('licenseManager.companyID'), 
        t('licenseManager.projectID'), 
        t('licenseManager.limitedDate'), 
        t('licenseManager.type'),
        t('licenseManager.path')
    ];
    const LicenseManager = () => {
        if (!licenseManager) {
            return <div>{t('CommonString.loading')}</div>;
        }
    
        const licenseManagerInfo = [
            licenseManager.uuid, 
            licenseManager.userID, 
            licenseManager.companyID, 
            licenseManager.projectID, 
            licenseManager.limitedDate, 
            licenseManager.type,
            licenseManager.path
        ];
    
        return (
            <Grid item xs={12} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Paper className='content-table' elevation={4} sx={{ width: '100%', padding: '12px', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '32px' }} >
                        <MesSliderTitle classNameSliderGrid='eapConnector-SliderIcon' classNameTitleText='eapConnector-SliderTitle' SliderTitleIcon={SettingDatabaseManagerSliderIcon} TitleText={t('licenseManager.title')} />
                    </div>
                    <TableContainer style={{ flexGrow: 1 }}>
                        <Table sx={{ minWidth: 650, height: '100%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: '#686667', fontSize: '18px' }}>{t('CommonString.Field')}</TableCell>
                                    <TableCell sx={{ color: '#686667', fontSize: '18px' }}>{t('CommonString.Value')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {licenseManagerLabels.map((label, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{ '& .MuiTableCell-root': { height: '50px' }, '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell sx={{ fontWeight: 'bold', color: '#686667', fontSize: '18px' }}>
                                            {label}
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }} >
                                            {licenseManagerInfo[index].toString()}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </Grid>
        );
    };
    
    


    // Constant Manager
    const [constantsTab, setConstantsTab] = useState(0);
    const [constantRows, setConstantRows] = useState([]);
    const [selectedConstant, setSelectedConstant] = useState(null);
    useEffect(() => {
        if (config) {
            axios.get(`${config.apiBaseUrl}/api/GetConstantManager`)
                .then(response => {
                    const constantsData = response.data;
                    const formattedData = Object.keys(constantsData).map((key, index) => ({
                        id: index,
                        name: key,
                        ...constantsData[key]
                    }));
                    setConstantRows(formattedData);
                    setSelectedConstant(formattedData[0]); // 初始化選擇第一個常量
                })
                .catch(error => {
                    console.error('There was an error fetching the constants data!', error);
                });
        }
    }, [config]);
    
    
    useEffect(() => {
        if (constantRows.length > 0) {
            setSelectedConstant(constantRows[constantsTab]);
        }
    }, [constantsTab, constantRows]);

    const constants = constantRows.map(x => ({
        id: x.id,
        title: x.name,
        Icon: () => { }
    }));

    const ConstantManager = () => {
        if (!selectedConstant) {
            return <div>{t('CommonString.loading')}</div>;
        }
        return (
            <Grid item xs={12} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={2} style={{ flexGrow: 1 }}>
                    <Grid item xs={3} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <Paper className='content-table' elevation={4} sx={{ width: '100%', padding: '12px', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginBottom: '32px' }} >
                                <MesSliderTitle classNameSliderGrid='eapConnector-SliderIcon' classNameTitleText='eapConnector-SliderTitle' SliderTitleIcon={SettingDatabaseListSliderIcon} TitleText={t('constantManager.ConstantName')} />
                            </div>
                            <MesVerticalTabFilled
                                filledTabClassName='eap-filled-tab'
                                filledTabListClassName='eap-filled-tab-list'
                                currentTab={constantsTab}
                                tabs={constants}
                                currentTabHandler={setConstantsTab}
                                style={{ flexGrow: 1 }} />
                        </Paper>
                    </Grid>
                    <Grid item xs={9} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <Paper className='content-table' elevation={4} sx={{ width: '100%', padding: '12px', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginBottom: '32px' }} >
                                <MesSliderTitle classNameSliderGrid='eapConnector-SliderIcon' classNameTitleText='eapConnector-SliderTitle' SliderTitleIcon={SettingDatabaseManagerSliderIcon} TitleText={selectedConstant.name} />
                            </div>
                            <TableContainer style={{ flexGrow: 1 }}>
                                <Table sx={{ minWidth: 650, height: '100%' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: '#686667', fontSize: '18px' }} >{t('CommonString.Field')} </TableCell>
                                            <TableCell sx={{ color: '#686667', fontSize: '18px' }} >{t('CommonString.Value')}</TableCell>
                                            <TableCell sx={{ color: '#686667', fontSize: '18px' }} >{t('CommonString.Description')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow sx={{ '& .MuiTableCell-root': { height: '50px' }, '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#686667', fontSize: '18px' }}>{t('constantManager.DefaultValue')}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }}>{selectedConstant.defaultValue.value}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }}>{selectedConstant.defaultValue.description}</TableCell>
                                        </TableRow>
                                        {Object.entries(selectedConstant.values).map(([key, val], index) => (
                                            <TableRow key={index} sx={{ '& .MuiTableCell-root': { height: '50px' }, '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell sx={{ fontWeight: 'bold', color: '#686667', fontSize: '18px' }}>{key}</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }}>{val.value}</TableCell>
                                                <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }}>{val.description}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    
    
    

    // Parameter
    const [parameterRows, setParameterRows] = useState([]);
    const [selectedParameter, setSelectedParameter] = useState(null);
    const [parameterTab, setParameterTab] = useState(0);

    useEffect(() => {
        if (config) {
            axios.get(`${config.apiBaseUrl}/api/GetParameterManager`)
                .then(response => {
                    const parameterData = response.data;
                    const formattedData = Object.keys(parameterData).map((key, index) => ({
                        id: index,
                        name: key,
                        ...parameterData[key]
                    }));
                    setParameterRows(formattedData);
                    setSelectedParameter(formattedData[0]); // 初始化選擇第一個參數
                })
                .catch(error => {
                    console.error('There was an error fetching the parameter data!', error);
                });
        }
    }, [config]);

    useEffect(() => {
        if (parameterRows.length > 0) {
            setSelectedParameter(parameterRows[parameterTab]);
        }
    }, [parameterTab, parameterRows]);

    const parameters = parameterRows.map(x => ({
        id: x.id,
        title: x.name,
        Icon: () => { }
    }));

    const ParameterManager = () => {
        if (!selectedParameter) {
            return <div>{t('CommonString.loading')}</div>;
        }
    
        return (
            <Grid item xs={12} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={2} style={{ flexGrow: 1 }}>
                    <Grid item xs={3} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <Paper className='content-table' elevation={4} sx={{ width: '100%', padding: '12px', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginBottom: '32px' }} >
                                <MesSliderTitle classNameSliderGrid='eapConnector-SliderIcon' classNameTitleText='eapConnector-SliderTitle' SliderTitleIcon={SettingDatabaseListSliderIcon} TitleText={t('ParameterManager.ParameterName')} />
                            </div>
                            <MesVerticalTabFilled
                                filledTabClassName='eap-filled-tab'
                                filledTabListClassName='eap-filled-tab-list'
                                currentTab={parameterTab}
                                tabs={parameters}
                                currentTabHandler={setParameterTab}
                                style={{ flexGrow: 1 }} />
                        </Paper>
                    </Grid>
                    <Grid item xs={9} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <Paper className='content-table' elevation={4} sx={{ width: '100%', padding: '12px', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginBottom: '32px' }} >
                                <MesSliderTitle classNameSliderGrid='eapConnector-SliderIcon' classNameTitleText='eapConnector-SliderTitle' SliderTitleIcon={SettingDatabaseManagerSliderIcon} TitleText={selectedParameter.name} />
                            </div>
                            <TableContainer style={{ flexGrow: 1 }}>
                                <Table sx={{ minWidth: 650, height: '100%' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: '#686667', fontSize: '18px' }} >{t('CommonString.Field')}</TableCell>
                                            <TableCell sx={{ color: '#686667', fontSize: '18px' }} >{t('CommonString.Value')}</TableCell>
                                            <TableCell sx={{ color: '#686667', fontSize: '18px' }} >{t('CommonString.Description')}</TableCell>
                                            <TableCell sx={{ color: '#686667', fontSize: '18px' }} >{t('CommonString.DataType')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow sx={{ '& .MuiTableCell-root': { height: '50px' }, '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#686667', fontSize: '18px' }}>{selectedParameter.name}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }}>{selectedParameter.value}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }}>{selectedParameter.description}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }}>{selectedParameter.dataType}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    

    // Database Manager
    const [databaseRows, setDatabaseRows] = useState([]);
    const [selectedDatabase, setSelectedDatabase] = useState(null);
    const [databaseTab, setDatabaseTab] = useState(0);

    useEffect(() => {
        if (config) {
            axios.get(`${config.apiBaseUrl}/api/GetDatabaseManager`)
                .then(response => {
                    const databaseData = response.data;
                    const formattedData = Object.keys(databaseData).map((key, index) => ({
                        id: index,
                        name: key,
                        ...databaseData[key]
                    }));
                    setDatabaseRows(formattedData);
                    setSelectedDatabase(formattedData[0]); // 初始化選擇第一個數據庫
                })
                .catch(error => {
                    console.error('There was an error fetching the database data!', error);
                });
        }
    }, [config]);

    useEffect(() => {
        if (databaseRows.length > 0) {
            setSelectedDatabase(databaseRows[databaseTab]);
        }
    }, [databaseTab, databaseRows]);

    const databases = databaseRows.map(x => ({
        id: x.id,
        title: x.name,
        Icon: () => { }
    }));

    const DatabaseManager = () => {
        if (!selectedDatabase) {
            return <div>{t('CommonString.NoData')}</div>;
        }
    
        return (
            <Grid item xs={12} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={2} style={{ flexGrow: 1 }}>
                    <Grid item xs={3} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <Paper className='content-table' elevation={4} sx={{ width: '100%', padding: '12px', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginBottom: '32px' }} >
                                <MesSliderTitle classNameSliderGrid='eapConnector-SliderIcon' classNameTitleText='eapConnector-SliderTitle' SliderTitleIcon={SettingDatabaseListSliderIcon} TitleText={t('DatabaseManager.DatabaseName')} />
                            </div>
                            <MesVerticalTabFilled
                                filledTabClassName='eap-filled-tab'
                                filledTabListClassName='eap-filled-tab-list'
                                currentTab={databaseTab}
                                tabs={databases}
                                currentTabHandler={setDatabaseTab}
                                style={{ flexGrow: 1 }} />
                        </Paper>
                    </Grid>
                    <Grid item xs={9} style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                        <Paper className='content-table' elevation={4} sx={{ width: '100%', padding: '12px', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginBottom: '32px' }} >
                                <MesSliderTitle classNameSliderGrid='eapConnector-SliderIcon' classNameTitleText='eapConnector-SliderTitle' SliderTitleIcon={SettingDatabaseManagerSliderIcon} TitleText={selectedDatabase.name} />
                            </div>
                            <TableContainer style={{ flexGrow: 1 }}>
                                <Table sx={{ minWidth: 650, height: '100%' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: '#686667', fontSize: '18px' }}>{t('CommonString.Field')}</TableCell>
                                            <TableCell sx={{ color: '#686667', fontSize: '18px' }}>{t('CommonString.Value')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow sx={{ '& .MuiTableCell-root': { height: '50px' }, '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#686667', fontSize: '18px' }}>{t('DatabaseManager.Source')}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }}>{selectedDatabase.source}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '& .MuiTableCell-root': { height: '50px' }, '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#686667', fontSize: '18px' }}>{t('DatabaseManager.Port')}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }}>{selectedDatabase.port}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '& .MuiTableCell-root': { height: '50px' }, '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#686667', fontSize: '18px' }}>{t('DatabaseManager.Catalog')}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }}>{selectedDatabase.catalog}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '& .MuiTableCell-root': { height: '50px' }, '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#686667', fontSize: '18px' }}>{t('DatabaseManager.User')}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }}>{selectedDatabase.user}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '& .MuiTableCell-root': { height: '50px' }, '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#686667', fontSize: '18px' }}>{t('DatabaseManager.ConnectionState')}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }}>{selectedDatabase.connectionState}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '& .MuiTableCell-root': { height: '50px' }, '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#686667', fontSize: '18px' }}>{t('DatabaseManager.DatabaseType')}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }}>{selectedDatabase.dbType}</TableCell>
                                        </TableRow>
                                        <TableRow sx={{ '& .MuiTableCell-root': { height: '50px' }, '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#686667', fontSize: '18px' }}>{t('DatabaseManager.ErrorMessage')}</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: '#002856', fontSize: '18px' }}>{selectedDatabase.errorMessage}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
    
    

    const BottomContent = (tabNumber) => {
        switch (tabNumber) {
            case 0:
                return LicenseManager();
            case 1:
                return ConstantManager();
            case 2:
                return ParameterManager();
            case 3:
                return DatabaseManager();
            default:
                break;
        }
    }

    return (
        <Grid container spacing={0}>
            <Grid item xs={12} className='layout-banner'>
                <MesTitlePage
                    classNameBox='layout-banner-box'
                    classNameTitlePageIcon='layout-banner-icon-white'
                    TitlePageIcon={EquipmentAutomationIcon}
                    TitleText={t('CommonString.EquipmentAutomation')}
                    SubTitleText={t('CommonString.Setting')}
                />
                <div style={{ width: 'fit-content' }} >
                    <MesTab
                        tabTitle={managers}
                        value={manager}
                        handleChange={(e, v) => { setManager(v); }}
                        tabSize='tab-size-EAPSetting'
                        tabContainerSize='tab-container-size-EAPSetting'
                        withScrollButtons={false}
                    />
                </div>
            </Grid>
            <Grid item xs={12} className='content-container'>
                <Grid container>
                    {BottomContent(manager)}
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state, ownProps) => {
    var SettingData = state.settingReducer.SettingData;
    return {
        SettingModel: { ...Object.assign({}, {}, SettingData) }
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSettingDataOnce: (state) => dispatch(SettingAction.SettingGetDataOnce(state)),
        createOrUpdateSettingData: (state) => dispatch(SettingAction.SettingCreateOrUpdateData(state)),
        deleteSettingData: (state) => dispatch(SettingAction.SettingDeleteData(state)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Setting);