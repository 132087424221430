import React from 'react';
import {
    GridFooterContainer,
    GridPagination,
} from '@mui/x-data-grid';
import { gridPageSelector, gridPageCountSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';

export default function MesGridTableDefaultFooter() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <GridFooterContainer className='content-table-top'>
            <GridPagination
                labelRowsPerPage=''
                rowsPerPageOptions={[]}
                nextIconButtonProps={{ style: { display: "none" } }}
                backIconButtonProps={{ style: { display: "none" } }}
                className='removeParagraphMargin'
                sx={{fontSize: '12px'}}
            />
            <div style={{ display: 'flex' }}>
                <GridPagination
                    labelDisplayedRows={({ from, to, count }) => { return '' }}
                    nextIconButtonProps={{ style: { display: "none" } }}
                    backIconButtonProps={{ style: { display: "none" } }}
                    className='removeParagraphMargin'
                />
                <div style={{ display: 'flex' }} >
                    <GridPagination
                        slotProps={{
                            actions: { previousButton: { disableRipple: true, } }
                        }}
                        labelRowsPerPage='' rowsPerPageOptions={[]}
                        labelDisplayedRows={({ from, to }) => { return '' }}
                        nextIconButtonProps={{ style: { display: "none" } }}
                        className='removePaginationActionMargin removePaginationActionPadding paginationActionButtons'
                    />
                    <GridPagination
                        labelRowsPerPage='' rowsPerPageOptions={[]}
                        nextIconButtonProps={{ style: { display: "none" } }}
                        backIconButtonProps={{ style: { display: "none" } }}
                        labelDisplayedRows={() => { return page + 1 + '/' + pageCount }}
                        className='removeParagraphMargin'
                    />
                    <GridPagination
                        slotProps={{
                            actions: { nextButton: { disableRipple: true, } }
                        }}
                        labelRowsPerPage='' rowsPerPageOptions={[]}
                        labelDisplayedRows={({ from, to }) => { return '' }}
                        backIconButtonProps={{ style: { display: "none" } }}
                        className='removePaginationActionMargin removePaginationActionPadding paginationActionButtons'
                    />
                </div>
            </div>
        </GridFooterContainer>
    );
}