import * as ConnectorConst from "../const/ConnectorConst";

export const ConnectorSetDataValue = (params) => {

    return {
        type: ConnectorConst.SET_CONNECTOR_DATA_VALUE,
        payload: params
    };
}

export const ConnectorGetDataValue = (params) => {

    return {
        type: ConnectorConst.GET_CONNECTOR_DATA_VALUE,
        payload: params
    };
}

export const ConnectorGetDataOnce = (params) => {

    return {
        type: ConnectorConst.GET_CONNECTOR_DATA_ONCE,
        payload: params
    };
}

export const ConnectorSetDataOnce = (params) => {

    return {
        type: ConnectorConst.SET_CONNECTOR_DATA_ONCE,
        payload: params
    };
}

export const ConnectorCreateOrUpdateData = (params) => {

    return {
        type: ConnectorConst.CREATE_OR_UPDATE_CONNECTOR_DATA,
        payload: params
    };
}

export const ConnectorDeleteData = (params) => {

    return {
        type: ConnectorConst.DELETE_CONNECTOR_DATA,
        payload: params
    };
}
