import React from 'react';
import { ReactComponent as MasterDataIcon } from '../shared/images/mesSideMenuIcon/MasterDataIcon.svg';
import { ReactComponent as SecurityIcon } from '../shared/images/mesSideMenuIcon/SecurityIcon.svg';
import { ReactComponent as WIPFillIcon } from '../shared/images/mesSideMenuIcon/WIPFillIcon.svg';
import { ReactComponent as MaintenanceIcon } from '../shared/images/mesSideMenuIcon/MaintenanceIcon.svg';
import { ReactComponent as InventoryIcon } from '../shared/images/mesSideMenuIcon/InventoryIcon.svg';
import { ReactComponent as QMSIcon } from '../shared/images/mesSideMenuIcon/QMSIcon.svg';
import { ReactComponent as IIOTHubIcon } from '../shared/images/mesSideMenuIcon/IIOTHubIcon.svg';
import { ReactComponent as IntegrationManagerIcon } from '../shared/images/mesSideMenuIcon/IntegrationManagerIcon.svg';
import { ReactComponent as FlowManagerIcon } from '../shared/images/mesSideMenuIcon/FlowManagerIcon.svg';
import { ReactComponent as DashboardBuilderIcon } from '../shared/images/mesSideMenuIcon/DashboardBuilderIcon.svg';
import { ReactComponent as ARManagerFillIcon } from '../shared/images/mesSideMenuIcon/ARManagerFillIcon.svg';
import { ReactComponent as LicenseManagerIcon } from '../shared/images/mesSideMenuIcon/LicenseManagerIcon.svg';

const mesMainMenuLayoutData = [
    {
        title: 'Master Data',
        icon: <MasterDataIcon />,
        link: '/master-data'
    },
    {
        title: 'Security',
        icon: <SecurityIcon />,
        link: '/security'
    },
    {
        title: 'WIP',
        icon: <WIPFillIcon />,
        link: '/wip'
    },
    {
        title: 'Maintenance',
        icon: <MaintenanceIcon />,
        link: '/maintenance'
    },
    {
        title: 'Inventory',
        icon: <InventoryIcon />,
        link: '/inventory'
    },
    {
        title: 'QMS',
        icon: <QMSIcon />,
        link: '/qms'
    },
    {
        title: 'IIOT Hub',
        icon: <IIOTHubIcon />,
        link: '/iiot-hub'
    },
    {
        title: 'Integration Manager',
        icon: <IntegrationManagerIcon />,
        link: '/integration-manager'
    },
    {
        title: 'Flow Manager',
        icon: <FlowManagerIcon />,
        link: '/flow-manager'
    },
    {
        title: 'Dashboard Builder',
        icon: <DashboardBuilderIcon />,
        link: '/dashboard-builder'
    },
    {
        title: 'AR Manager',
        icon: <ARManagerFillIcon />,
        link: '/ar-manager'
    },
    {
        title: 'License Manager',
        icon: <LicenseManagerIcon />,
        link: '/license-manager'
    },
]

export default mesMainMenuLayoutData;